.new-btn {
    margin-bottom: 20px !important;
}

.agenda-comercial{
    position: relative;
    margin-top: 0px;
    padding: 0 20px;
    box-sizing: border-box;
}
/*.agenda-comercial-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 400px;
    padding-bottom: 60px;
}*/
.agenda-comercial-container-scrol {
    width: 100%;
    overflow-x: scroll;
    overflow-y:hidden;
    height: 20px;
    display: none;
}
.agenda-comercial-container-div {
    width: calc(237.15px * 7 );
    height: 20px;
}
.agenda-comercial-container-scrol::-webkit-scrollbar {
    width: 1px;
}
.agenda-comercial-container-scrol::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px #7d00ff;
    border-radius: 0px;
}
.agenda-comercial-container-scrol::-webkit-scrollbar-thumb {
    border-radius: 0px;
    -webkit-box-shadow: inset 0 0 2px #7d00ff;
    background: linear-gradient(to right,#7d00ff 0%,#c33fff 120%);
    height: 5px;
}
.agenda-comercial-container-scrol-nutricao::-webkit-scrollbar {
    width: 1px;
}
.agenda-comercial-container-scrol-nutricao::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px #067843;
    border-radius: 0px;
}
.agenda-comercial-container-scrol-nutricao::-webkit-scrollbar-thumb {
    border-radius: 0px;
    -webkit-box-shadow: inset 0 0 2px #067843;
    background: linear-gradient(to right,#0BE881 0%,#067843 120%);
    height: 5px;
}

.agenda-comercial-container-scrol-acupuntura::-webkit-scrollbar-thumb {
    border-radius: 0px;
    -webkit-box-shadow: inset 0 0 2px #067843;
    background: linear-gradient(to right,#fbb03b 0%, #ffe66a 120%);
    height: 5px;
}


.agenda-comercial-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    
    flex-direction: column;
    min-height: 400px;
    padding-bottom: 60px;
    margin-top: 120px;
    overflow: hidden;
    overflow-x: scroll;
    margin-bottom: 80px;
    padding: 0 20px;
    box-sizing: border-box;
}
.agenda-comercial-container-tot {
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: space-between;  
    flex-direction: row;
    min-height: 40px;
    padding-bottom: 60px;
    margin-top: 0px;
    overflow: hidden;
    overflow-x: hidden;
    margin-bottom: 0px;
    padding: 0 20px;
    box-sizing: border-box;
}
.agenda-comercial-container-tot .agenda-column {
    min-width:unset;  
}

.agenda-comercial-container-tot .agenda-column h4 {
   color:grey;
}

.agenda-comercial-container-tot .agenda-column h3 {
   color:#cc66cc;
   font-style: normal;
}

.agenda-comercial-container-tot.nutri .agenda-column  h3 {
   color:#067843;
   font-style: normal;
}

.agenda-comercial-container-tot.acupuntura .agenda-column  h3 {
   color:#ffcc00;
   font-style: normal;
}

.current-mes-week {margin-top: 10px !important;}

.current-mes-week.nutri {color: #067843;}

.agenda-comercial-container::-webkit-scrollbar {
    width: 1px;
}
.agenda-comercial-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px #7d00ff;
    border-radius: 0px;
}
.agenda-comercial-container::-webkit-scrollbar-thumb {
    border-radius: 0px;
    -webkit-box-shadow: inset 0 0 2px #7d00ff;
    background: linear-gradient(to right,#7d00ff 0%,#c33fff 120%);
    height: 5px;
}


.agenda-comercial-container-nutricao::-webkit-scrollbar {
    width: 1px;
}
.agenda-comercial-container-nutricao::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px #067843;
    border-radius: 0px;
}
.agenda-comercial-container-nutricao::-webkit-scrollbar-thumb {
    border-radius: 0px;
    -webkit-box-shadow: inset 0 0 2px #067843;
    background: linear-gradient(to right,#0BE881 0%,#067843 120%);
    height: 5px;
}

.agenda-comercial-container-nutricao .agenda-column h3 , .buttons-days-nutricao a{
    color: #067843 !important;
}



.agenda-comercial-container-acupuntura .agenda-column h3 , .buttons-days-acupuntura a{
    color: #ffcc00 !important;
}

.agenda-comercial-container-acupuntura::-webkit-scrollbar {
    width: 1px;
}
.agenda-comercial-container-acupuntura::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px #ffcc00;
    border-radius: 0px;
}
.agenda-comercial-container-acupuntura::-webkit-scrollbar-thumb {
    border-radius: 0px;
    -webkit-box-shadow: inset 0 0 2px #ffcc00;
    background: linear-gradient(to right,#fbb03b 0%, #ffe66a 120%);
    height: 5px;
}
.current-mes-week-acupuntura {
    color: #ffcc00 !important;
}
.agenda-title {
    width: 100%;
    position: relative;
    padding: 14px 20px;
    box-sizing: border-box;
    background: -webkit-linear-gradient(top, #c33fff 0%, #7d00ff 150%);
    background: linear-gradient(to bottom, #c33fff 50%, #7d00ff 180%);
    text-align: center;
    margin: 40px 0;
    margin-top: 20px;
    color: white;
    font-weight: 600;
    font-style: italic;
    font-size: 18px;
    letter-spacing: 1.5px;
    height: 50px;
}
.form-agenda-comercial{
    background: #6ab6ff;
    background: -moz-linear-gradient(left, #6ab6ff 0%, #3f86ff 100%);
    background: -webkit-linear-gradient(left, #6ab6ff 0%,#3f86ff 100%);
    background: linear-gradient(to right, #6ab6ff 0%,#3f86ff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6ab6ff', endColorstr='#3f86ff',GradientType=1 );
    height: 100%;
    border-radius: 20px;
    width: 100%;
    padding: 20px 20px;
    box-sizing: border-box;
    position: relative;
    padding-bottom: 60px;
    z-index: 9999;
}
.agenda-column {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.model-agenda-comercial,.model-agenda-comercial-edit, .model-agenda-comercial-status-report{
    display: none;
    position: fixed;
    bottom: 20px;
    right: 0;
    width: 90%;
    height: auto;
    z-index: 99999999;
}
.model-agenda-comercial.active,.model-agenda-comercial-edit.active , .model-agenda-comercial-status-report.active{
    display: block;
}
.model-agenda-comercial-status-report .content-form-agenda{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
    background:  -webkit-linear-gradient(to right, #ffe66a -5%, #fbb03b 100%);
    padding: 50px;
    box-sizing: border-box;
    border-radius: 40px;
}

.model-agenda-comercial-status-report .content-form-agenda button {
    position: relative;
    float: none;
    margin-bottom: 20px;
    bottom: 0;
    right: 0;
    left: 0;
}
.agenda-column h3 {
    color:  #cc66cc;
    font-style: italic;
    font-size: 20px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 10px;
    min-height: 30px;
}


.user-info-item{
    background: -webkit-linear-gradient(top, #c33fff 0%, #7d00ff 120%);
    background: linear-gradient(to bottom, #c33fff 0%, #7d00ff 120%);
}
.date-item-column {
    width: 100%;
}
.agenda-title{
    padding: 0;
    display: flex;
    flex-direction: column;
}
.agenda-title button:first-child {
    width: 100%;
    margin: 0;
    display: inline-block;
    padding: 14px;
    border: 0;
    background: green;
    color: white;
    font-size: 20px;
    line-height: 20px;
    text-align: left;
    font-style: italic;
    font-weight: 600;
    background: linear-gradient(to bottom, #22b573 35%, #006837 180%);
    -webkit-transition: all 0.3s linear ;
    -moz-transition: all 0.3s linear ;
    -ms-transition: all 0.3s linear ;
    -o-transition: all 0.3s linear ;
    transition: all 0.3s linear ;
    cursor: pointer;
}

.agenda-title button:last-child{
    width: 100%;
    margin: 0;
    display: inline-block;
    padding: 14px;
    line-height: 20px;
    border: 0;
    color: white;
    font-size: 20px;
    text-align: left;
    font-style: italic;
    font-weight: 600;
    background: linear-gradient(to bottom, #c33fff 50%, #7d00ff 180%);
    -webkit-transition: all 0.3s linear ;
    -moz-transition: all 0.3s linear ;
    -ms-transition: all 0.3s linear ;
    -o-transition: all 0.3s linear ;
    transition: all 0.3s linear ;
    cursor: pointer;

}
.buttons-days{
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
}
.buttons-days a{
    color: #cc66cc;
    text-decoration: none;
    font-size: 12px;
    font-style: italic;
    line-height: 40px;
    display: flex;
    text-transform: uppercase;
}
.buttons-days a svg {
    width: 10px;
    margin-right: 10px;
}
.buttons-days a:last-child svg{
    margin-left: 10px;
    margin-right: 0;
}
.agenda-title button:focus{
    outline: none;
}
.form-agenda-comercial,  .agenda-item{
    background: -webkit-linear-gradient(top, #ffe66a -5%, #fbb03b 100%) !important;
}
.time{
    background: linear-gradient(to bottom, #6ab6ff 20%, #3f86ff 100%) ;
}
.content-form-agenda {
    width: calc(100%);
    margin-left: 0;
    flex-direction: column;
}
.content-form-agenda .normla-input input {
    flex: 1 0 0;
    background: transparent;
    border: 0;
    padding: 4px 0px;
    font-style: italic;
    font-size: 18px;
    color: white;
    border-bottom: 2px solid;
    margin-bottom: 20px;
}

.content-form-agenda label{
    font-size: 18px;
    display: block;
}

.agenda-item,
.agenda-marcacao {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-radius: 20px;
    background: -webkit-linear-gradient(to right, #fbb03b -0%, #ffe66a 120%) !important;
    background: linear-gradient(to right, #fbb03b 0%, #ffe66a 120%) !important;

    color: white;
    padding: 0;
    box-sizing: border-box;
    font-style: italic;
    cursor: pointer;
    margin-bottom: 10px;
}
.agenda-marcacao{
    background: linear-gradient(to right,#7d00ff 0%,#c33fff 120%) !important;
}
.new-appointment.livre {
    background: -webkit-linear-gradient(to right, #fbb03b -0%, #ffe66a 120%) !important;
    background: linear-gradient(to right, #fbb03b 0%, #ffe66a 120%) !important;
}
.new-appointment.marcacao {
    background: -webkit-linear-gradient(to right,#7d00ff 0%,#c33fff 120%) !important;
    background: linear-gradient(to right,#7d00ff 0%,#c33fff 120%) !important;
}
.agenda-item:hover .type-title ,.agenda-marcacao:hover .type-title{
    opacity: 1;
}
.agenda-item:hover .name-title ,.agenda-marcacao:hover .name-title{
    opacity: 0
}
.agenda-marcacao.hide{
    display: none;
}
.agenda-item .time,.agenda-marcacao .time{
    background: transparent;
    border-radius: 20px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding: 10px 6px;
    box-sizing: border-box;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    width: 68px;
}

.name-type{
    position: relative;
    display: block;
    max-height: 42px;
    width: 100%;
    text-align: center;
    transition: all 0.3s linear;
}
.name-title {
    line-height: 14px;
    transition: all 0.3s linear;
    text-transform: uppercase;
    font-size: 14px;
}
.name-title{
    line-height: 42px;
}
.type-title{
    opacity: 0;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    top: 10px;
    right: 0;
    width: 100%;
    line-height: 24px;
    height: 42px;
}
.agenda-marcacao .type-title{
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 40px;
    margin-top: -9px;
    top: 18px;
    padding-left: 50px;
    padding-right: 20px;
    box-sizing: border-box;
}
.agenda-marcacao .type-title p {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
}
.agenda-marcacao .type-title::-webkit-scrollbar {
    width: 0px;
}
.agenda-marcacao .type-title::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px transparent;
    border-radius: 40px;
}
.agenda-marcacao .type-title::-webkit-scrollbar-thumb {
    border-radius: 40px;
    -webkit-box-shadow: inset 0 0 1px #7d00ff;
    background:  linear-gradient(to right,#7d00ff 50%,#c33fff 180%);
    height: 1px;
}
.agenda-comercial .add-button-plus-gradient{
    right: calc(50% - 26px);
}
.new-appointment form {
    width: 100%;
}
.new-appointment form label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.projecao-comercial {
    position: relative;
    padding: 0 20px;
    box-sizing: border-box;
    margin-top: 40px;
}
#agenda-projecao-container{
    overflow: hidden;
    min-height: 180px;
    margin-bottom: 40px;
}
#agenda-projecao-container .agenda-item-container {
    display: flex;
    flex-direction: column;
}
#agenda-projecao-container .agenda-column:first-child span {
    font-style: italic;
    font-weight: 700;
}
#agenda-projecao-container .agenda-item-container span {
    margin-top: 10px;
    text-align: center;
}
#agenda-projecao-container .agenda-item-container span input {
    width: 100%;
    max-width: 50px;
    border: 0;
    background: transparent;
    border-bottom: 1px solid black;
    text-align: center;
}
#agenda-projecao-container .agenda-item-container span:last-child{
    margin-top: 20px;
}
.total-projeccoes {
    margin-top: 40px;
    margin-bottom: 40px;
}
.total-projeccoes strong{
    font-style: italic;
}
.total-projeccoes input {
    background:transparent;
    border: 0;
}
.new-appoint-nutricao.marcacao {
    background: linear-gradient(to bottom,#0BE881 0%,#067843 120%) !important;
}
.agenda-comercial-container-nutricao .agenda-marcacao {
    background: linear-gradient(to right,#0BE881 0%,#067843 120%) !important;

}
.new-appoint-acupuntura.marcacao {
    background: linear-gradient(to bottom,#fbb03b 0%, #ffe66a 120%) !important;
}
.agenda-comercial-container-acupunturao .agenda-marcacao {
    background: linear-gradient(to right,#fbb03b 0%, #ffe66a 120%) !important;

}
#realizada-btn{
    background: linear-gradient(to bottom, #22b573 35%, #006837 180%) !important;
}
#nao-realizada-btn{
    background: linear-gradient(to right, red 0%,darkred 100%) !important;
}
#reagendade-btn{
    background: linear-gradient(to right, #6ab6ff 0%,#3f86ff 100%) !important;
}

.realizada-class{
    background: linear-gradient(to bottom, #22b573 35%, #006837 180%) !important;
}
.nao-ralizada-class {
    background: linear-gradient(to right, red 0%,darkred 100%) !important;
}
.reagendada-class{
    background: linear-gradient(to right, #6ab6ff 0%,#3f86ff 100%) !important;
}
.table-inputs-filtros-contactos-nutricao {
    background: linear-gradient(to right,#0BE881 0%,#067843 120%) !important;
}

.table-inputs-filtros-contactos label {min-width: 400px;}

.pt-list-rota ul{
    grid-template-columns: 25% 25% 25% 25%;
    display: grid;
}
.pt-list-rota ul li{
    list-style: none;
    font-style: italic;
}
.pt-list-rota ul li strong {
    font-weight: 600;
    color: #7d00ff;
}
@media screen and (min-width: 1024px) {
    .agenda-comercial-container-scrol{
        display: block;
    }
    .agenda-title{
        font-size: 24px;
    }
    .agenda-comercial-container {
        flex-direction: row;
        margin-top: 20px;
        padding: 0;
    }
    .agenda-column {
        width: calc(95% / 7);
        min-width: 220px;
        margin-right: 20px;
    }
    #agenda-projecao-container .agenda-column {
        width: calc(95% / 9);
        min-width: 0px;
        margin-right: 20px;
    }

    .calendar-scroll-info{
        display: flex;
    }
    .agenda-title button:first-child {
        border-bottom-right-radius: 50px;
    }
    .agenda-title{
        flex-direction: row;
    }

    .agenda-title button.active{
        width: 85%;
    }
    .agenda-title button{
        width: 40%;
    }
}

/*
.top-info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 20px;
}
.top-info-column {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.top-info .top-info-column:last-child {
    display: none;
}
.user-info-item {
    background: -webkit-linear-gradient(top, #ffe66a -5%, #c652df 200%);
    border-radius: 40px;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    position: relative;
}
.user-info-item h2 {
    color: white;
    position: relative;
    text-transform: uppercase;
    margin: 20px 0 0 0;
    letter-spacing: 1px;
}
.user-info-item .top-info-date {
    color: white;
    position: relative;
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
}
.date-item-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 49%;
    background: -webkit-linear-gradient(top, #c33fff 0%, #7d00ff 120%);
    background: linear-gradient(to bottom, #c33fff 0%, #7d00ff 120%);
    padding: 20px;
    box-sizing: border-box;
    border-radius: 40px;
}
.resume-day {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
}
.date-item-column h2 {
    color: white;
    font-size: 32px;
    margin: 0;
    margin-bottom: 10px;
}
.date-item-column span {
    color: white;
    position: relative;
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
}
.date-change-top-info {
    display: flex;
    height: 50px;
    width: 100%;
    position: relative;
    justify-content: space-between;
}
.date-change-top-info a {
    color: #cc66cc;
    text-decoration: none;
    font-size: 12px;
    font-style: italic;
    line-height: 40px;
}
.resumo-week-info {
    background: -webkit-linear-gradient(top, #c33fff 0%, #7d00ff 150%);
    background: linear-gradient(to bottom, #c33fff 0%, #7d00ff 150%);
    padding: 12px 20px;
    box-sizing: border-box;
    border-radius: 20px;
    color: white;
}
.agenda-title {
    width: 100%;
    position: relative;
    padding: 14px 20px;
    box-sizing: border-box;
    background: -webkit-linear-gradient(top, #c33fff 0%, #7d00ff 150%);
    background: linear-gradient(to bottom, #c33fff 50%, #7d00ff 180%);
    text-align: center;
    margin: 40px 0;
    color: white;
    font-weight: 600;
    font-style: italic;
    font-size: 18px;
    letter-spacing: 1.5px;
    height: 80px;
}
.agenda-title a {
    position: absolute;
    left: 20px;
    text-decoration: none;
    font-size: 14px;
    color: white;
    bottom: 20px;
}
.agenda-title a:last-child {
    right: 20px;
    left: inherit;

}
.agenda-comercial {
    position: relative;
}
.agenda-comercial-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 400px;
    padding-bottom: 60px;
}
.form-agenda-comercial{
    background: #6ab6ff;
    background: -moz-linear-gradient(left, #6ab6ff 0%, #3f86ff 100%);
    background: -webkit-linear-gradient(left, #6ab6ff 0%,#3f86ff 100%);
    background: linear-gradient(to right, #6ab6ff 0%,#3f86ff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6ab6ff', endColorstr='#3f86ff',GradientType=1 );
    height: 100%;
    border-radius: 20px;
    width: 100%;
    padding: 20px 20px;
    box-sizing: border-box;
    position: relative;
    padding-bottom: 60px;
    z-index: 9999;
}
.agenda-column {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.model-agenda-comercial,.model-agenda-comercial-edit, .model-agenda-comercial-status-report{
    display: none;
    position: fixed;
    bottom: 20px;
    right: 0;
    width: 90%;
    height: auto;
    z-index: 99999999;
}


@media screen and (min-width: 1024px){
    .top-info{
        flex-direction: row;
    }
    .top-info-column{
        width: calc(95% / 3);
    }
    .top-info .top-info-column:last-child{
        display: flex;
    }
    .agenda-title{
        font-size: 24px;
    }
    .agenda-comercial-container {
        flex-direction: row;
    }
    .agenda-column {
        width: calc(95% / 7);
    }
    .date-item-column {
        width: 100%;
    }
}*/
