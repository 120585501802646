/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
    padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

/* Set core body defaults */
body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
    list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
    max-width: 100%;
    display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
    margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}
:root {
    --main-bg-color: #fff1e3;
    --text-color: #2e2516;
    --cta-color: #f4552e;
}
body {
    font-family: "Roboto";
    color: var(--text-color);
}
body, html, #root {
    margin: 0;
    width: 100%;
    height: 100%;
    word-wrap: break-word;
    font-family: Roboto, sans-serif;
    background: white;
}
.breadcrumbs{
    width: 100%;
    display: flex;
    color: #7d00ff;
    font-size: 10px;
    margin-top: 20px;
    padding: 0 20px;
    box-sizing: border-box;
}
.breadcrumbs a{
    text-decoration: none;
    margin-right: 10px;
    color: #7d00ff;
}
.breadcrumbs a:not(:first-child){
    margin-left: 10px;
}
.breadcrumbs a:last-child{
    border-bottom: 2px solid #7d00ff;
}
@media screen and (min-width: 1024px) {
    .breadcrumbs{
        font-size: 14px;
    }
}
/*

!*
.container{
    width: 100%;
    max-width: 1280px;
    margin:0 auto;
}*!
i.fae {
    width: 26px;
    height: 26px;
    background-size: cover;
    display: inline-block;
}

i.logout-fa {
    background: url(./img/sign-out-light.svg) no-repeat;
}

i.receita-fa {
    background: url(./img/chart-bar-light.svg) no-repeat;
}

i.dashboard-fa {
    background: url("./img/home-alt-light.svg") no-repeat;
}

i.users-fa {
    background: url(./img/users-medical-light.svg) no-repeat;
}

i.adesao-fa {
    background: url(./img/project-diagram-light.svg) no-repeat;
}

i.funcionarios-fa {
    background: url(./img/user-tie-light.svg) no-repeat;
}

i.fae-lock {
    background: url(./img/lock-alt-light.svg) no-repeat;
}

i.users-goup-fa {
    background: url(./img/users-class-light.svg) no-repeat;
}

i.dumbel-fa {
    background: url(./img/dumbbell-light.svg) no-repeat;
}

i.viagem-fa {
    background: url(./img/plane-departure-light.svg) no-repeat;
}
i.save-fa {
    background: url(./img/save-light.svg) no-repeat;
}
i.trash-fa {
    background: url(./img/trash-light.svg) no-repeat;
}
i.socio-fa {
    background: url(./img/walking-light.svg) no-repeat;
    background-position: center;
}

i.fa-leads {
    background: url(./img/leads.svg) no-repeat;
}
i.fa-pos {
    background: url(./img/pos.svg) no-repeat;
    background-position: center;
}
i.plus-light {
    background: url(./img/plus-light.svg) no-repeat;
    position: absolute;
    top: 11px;
    left: 14px;
}
i.times-light {
    background: url(./img/times-light.svg) no-repeat;
}
i.alertas-pt-fa {
    background: url(./img/bell-alert.png) no-repeat;
    background-size: contain;
    border-radius: 50px;
    width: 50px;
    height: 50px;
}
.alertas-pt {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;

}
.alertas-pt p {
    position: absolute;
    color: red;
    top: -18px;
    right: -9px;
    width: 6px;
    height: 6px;
    background: rgba(255,255,255,0.5);
    padding: 8px 8px;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    font-size: 14px;
    border: 2px solid red;
    line-height: 6px;
}
.model-alerts,.model-delete {
    display: none;
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: 9999999999999999;

}
.model-delete label {
    color: white;
    margin-bottom: 20px;
}
.close-model-alerts,.close-model-delete{
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;z-index: 1;
}
.close-model-delete{
    top:5px;
}
.model-alerts .funcionarios-table {
    width: 400px;
    background: linear-gradient(to bottom, #c33fff 0%, #7d00ff 120%);
    border-radius: 40px;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    position: relative;
}
.model-alerts  .alertas-viagems th{
    color: #ffe66a;
}
.model-alerts  .alertas-viagems td{
    background: #fcee21;
    background: -moz-linear-gradient(top, #fcee21 0%, #f89d1e 100%)  !important;
    background: -webkit-linear-gradient(top, #fcee21 0%, #f89d1e 100%)  !important;
    background: linear-gradient(to bottom, #fcee21 0%, #f89d1e 100%) !important;
    padding: 6px 12px;
}
.model-alerts.active,.model-delete.active {
    display: flex;

}
.row {
    padding: 0 20px;
    box-sizing: border-box;
}

.page-header {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    height: 100px;
    width: 100%;
    z-index: 99999;
    background: #c33fff; !* Old browsers *!
    background: -moz-linear-gradient(bottom,#7d00ff 50%,#c33fff 100%); !* FF3.6-15 *!
    background: -webkit-linear-gradient(bottom,#7d00ff 50%,#c33fff 100%); !* Chrome10-25,Safari5.1-6 *!
    background: linear-gradient(to bottom,#7d00ff 50%,#c33fff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c33fff', endColorstr='#7d00ff', GradientType=0); !* IE6-9 *!

}

.page-header .logo-bg {
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    max-width: 220px;
    display: none;
    margin: 0 auto;
    margin-top: 8px;
}

.logo-mobile {
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    max-width: 180px;
    display: block;
    margin: 0 auto;
    margin-top: 8px;
}

.logo-mobile img {
    width: 100%;
}

.icon-menu-mobile {
    display: block;
    position: absolute;
    bottom: 26px;
    right: 20px;
    background: white;
    width: 24px;
    height: 16px;
    cursor: pointer;
    z-index: 9999;
    padding: 16px 12px;
    border-radius: 100%;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
    transition: all 0.3s linear;
}

.icon-menu-mobile-logout {
    display: block;
    position: absolute;
    bottom: 26px;
    left: 20px;
    background: #ffe66a; !* Old browsers *!
    background: -moz-linear-gradient(top, #ffe66a 0%, #c652df 100%); !* FF3.6-15 *!
    background: -webkit-linear-gradient(top, #ffe66a 0%, #c652df 100%); !* Chrome10-25,Safari5.1-6 *!
    background: linear-gradient(to bottom, #ffe66a 0%, #c652df 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe66a', endColorstr='#c652df', GradientType=0); !* IE6-9 *!
    width: 24px;
    height: 16px;
    cursor: pointer;
    z-index: 9999;
    padding: 16px 12px;
    border-radius: 100%;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
    transition: all 0.3s linear;
}

!*.icon-menu-mobile.active {
    right: calc(50% - 25px);
}*!
.bar {
    width: 24px;
    position: absolute;
    height: 3px;
    border-radius: 20%;
    background: linear-gradient(135deg, rgba(92, 101, 168, 1) -15%, rgba(177, 118, 172, 1) 72%, rgba(92, 101, 168, 1) 100%);
    transition: all 0.3s linear;
}

.top-bar {
    top: 16px;
}

.middle-bar {
    top: 22px;
    opacity: 1;
}

.bottom-bar {
    top: 28px;
}

.mobile-menu-container {
    position: absolute;
    bottom: 0;
    right: -100%;
    height: 100%;
    width: 75%;
    background: none;
    border-radius: 100%;
    transition: all 0.3s linear;

}

.mobile-menu-container.active {
    bottom: 0;
    right: 0;
    width: 75%;
    opacity: 1;
    display: block;
    background: rgba(204, 51, 255, 0.9);
    height: 100%;
    position: fixed;
    border-radius: 0;
    z-index: 9999;

}

.mobile-menu-container.active #menu-ul-mobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    padding-top: 40px;
}

.mobile-menu-container.active #menu-ul-mobile li {
    line-height: 28px;
    text-align: center;
}

.mobile-menu-container.active #menu-ul-mobile li .sub-menu li {
    line-height: 28px;
}

.mobile-menu-container.active #menu-ul-mobile li a {
    font-size: 1rem;
}

.icon-menu-mobile.active .top-bar {
    transform: rotate(45deg);
    top: 22px;
}

.icon-menu-mobile.active .middle-bar {
    opacity: 0;
}

.icon-menu-mobile.active .bottom-bar {
    transform: rotate(-45deg);
    top: 22px;
}

.mobile-menu img:not(.logo-mobile) {
    opacity: 0;
}

.desktop-menu-ul {
    display: none;
}

.menu-container {
    display: none;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.mobile-menu-container.active .menu-container {
    display: block;
}

.page-header a {
    position: relative;
    display: inline-block;
    color: white;
    text-decoration: none;
    padding: 12px 0;
    transition: all 0.3s linear;
    font-weight: lighter;
    text-align: center;
    width: 100%;
    cursor: pointer;
    background: transparent;
}

.menu-container > span {
    flex: 1 0 0;
    color: white;
    text-transform: uppercase;
    padding: 12px;
    cursor: pointer;
    text-align: center;
    width: 100%;
    cursor: pointer;
}

.menu-container .in-menu {
    position: relative;
    display: inline-block;
    color: white;
    text-decoration: none;
    padding: 12px 0px;
    transition: all 0.3s linear;
    cursor: pointer;
    font-weight: lighter;
    flex: none;
    width: 100%;
    text-transform: none;
}

.menu-container > span.active ul {
    display: block;
    position: absolute;
    bottom: -118px;
    right: 0;
    padding: 20px;
    background: #552cc6;
}

.menu-container > .in-menu.active ul {
    top: 63px;
    bottom: unset;
    left: 0;
    right: none;
    padding: 20px;
    background: #552cc6;
    box-sizing: border-box;
    width: 160px;
}

.menu-container > .in-menu.active ul li a {
    padding: 6px 0;
}

.menu-container > span ul {
    display: block;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    width: 200px;
    top: -40px;
    left: 100px;
    background: #c33fff; !* Old browsers *!
    background: -moz-linear-gradient(top, #c33fff 0%, #7d00ff 100%); !* FF3.6-15 *!
    background: -webkit-linear-gradient(top, #c33fff 0%, #7d00ff 100%); !* Chrome10-25,Safari5.1-6 *!
    background: linear-gradient(to bottom, #c33fff 0%, #7d00ff 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c33fff', endColorstr='#7d00ff', GradientType=0); !* IE6-9 *!

    padding: 20px;
    box-sizing: border-box;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;

}

.menu-container > span ul.active {
    display: block;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s linear;
}

.menu-container > span ul li {
    list-style: none;
    line-height: 12px;
    text-align: left;
    padding: 0;
    font-size: 16px;
    margin-bottom: 6px;
}

.menu-container > .in-menu ul.active li a {
    text-align: left;
}

.page-header a span, .page-header span span {
    display: block;
}

.page-header a:hover, .menu-container .in-menu:hover {

}

.page-header .logo-bg img {
    display: block;
    width: 100%;
    max-width: 60px;
    margin: 0 auto;
}

.breadcrumb {
    background-color: transparent;
    background: none;
    margin-bottom: 40px;
}

.breadcrumb ul {
    display: flex;
    padding: 0;
}

.breadcrumb ul li {
    display: inline-block;
    margin-right: 6px;
    font-size: 22px;
}

.breadcrumb ul li a {
    text-decoration: none;
    color: black;
}

.breadcrumb ul li:last-child a {
    color: #552cc6;
}

#main-container {
    width: 100%;
    display: block;
    position: relative;
    margin-top: 100px;
    box-sizing: border-box;
}

.content-container {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.objectivos_form {
    background: linear-gradient(to bottom, #c33fff -35%, #7d00ff 120%);
    padding: 20px;
    box-sizing: border-box;
    border-radius: 20px;
    width: 100%;
    max-width: 840px;
    position: relative;
    box-shadow: -2px 4px 6px rgba(0, 0, 0, 0.2);
    padding-bottom: 60px;
}
.objectivos_form label {
    color: white;
}
.banco_dd-table p{
    color: white;
    display: inline-block !important;
    flex: 0 0 48% !important;
}
.objectivos_form h4 {
    color: white;
    font-weight: 500;
    display: inline-block;
    font-size: 28px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.btn-primary {
    background: #ffcc00;
    border: 0;
    padding: 8px 22px;
    color: white;
    font-style: italic;
    font-size: 22px;
    position: absolute;
    right: 20px;
    bottom: 20px;
    cursor: pointer;
    border-radius: 20px;
    box-shadow: -2px 4px 6px rgba(0, 0, 0, 0.2);
}

.btn-primary:hover {
    background: #ffcc00;
    transition: background 0.3s ease-out;
}

table {
    width: 100%;
}

table.viagem-socio-table  input {
    border: 0;
    background: transparent;
    font-size: 16px;
    text-align: center;
    font-weight: 300;
    width: 120px;
}

.receitas-table input {
    width: 100% !important;
}

.objetivos-v2-container {
    justify-content: space-between;
}

table.viagem-socio-table  input:focus {
    outline: none;
}

input:focus {
    outline: none;
}

table.viagem-socio-table  > thead:first-child > tr:first-child > th {
    font-size: 20px;
    font-weight: 500;
    padding: 8px 0;
    color: #cc66cc;
    font-style: italic;
}

table.viagem-socio-table  > tbody > tr > td {
    padding: 6px 0;
    text-align: center;
    border-bottom: 1px solid transparent;
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    color: white;
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
}

table.viagem-socio-table  > tbody > tr:nth-child(even) td{
    background: #fcee21; !* Old browsers *!
    background: -moz-linear-gradient(top, #fcee21 0%, #f89d1e 100%); !* FF3.6-15 *!
    background: -webkit-linear-gradient(top, #fcee21 0%, #f89d1e 100%); !* Chrome10-25,Safari5.1-6 *!
    background: linear-gradient(to bottom, #fcee21 0%, #f89d1e 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcee21', endColorstr='#f89d1e', GradientType=0); !* IE6-9 *!

}

table.viagem-socio-table  > tbody > tr:nth-child(odd) td{
    !* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#c69c6d+0,754c24+100 *!
    background: #c69c6d; !* Old browsers *!
    background: -moz-linear-gradient(top, #c69c6d 0%, #754c24 100%); !* FF3.6-15 *!
    background: -webkit-linear-gradient(top, #c69c6d 0%, #754c24 100%); !* Chrome10-25,Safari5.1-6 *!
    background: linear-gradient(to bottom, #c69c6d 0%, #754c24 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c69c6d', endColorstr='#754c24', GradientType=0); !* IE6-9 *!
}

table.viagem-socio-table  > tbody > tr > td:first-child {
    border-radius: 20px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
table.viagem-socio-table  > tbody > tr > td:nth-child(2),table > tbody > tr > td:nth-child(3){
    border-radius: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
table.viagem-socio-table  > tbody > tr > td:last-child{
    background: -webkit-linear-gradient(top, #c33fff 20%, #7d00ff 100%);
    background: linear-gradient(to bottom, #c33fff 20%, #7d00ff 100%) !important;
}
table.viagem-socio-table  > tbody > tr > td:last-child button{
    background: transparent;
    border: 0;
    cursor: pointer;
}
.tr-first-viagem td{
    !* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e6e6e6+0,b3b3b3+100 *!
    background: #e6e6e6 !important;; !* Old browsers *!
    background: -moz-linear-gradient(top,  #e6e6e6 0%, #b3b3b3 140%) !important;; !* FF3.6-15 *!
    background: -webkit-linear-gradient(top,  #e6e6e6 0%,#b3b3b3 140%) !important;; !* Chrome10-25,Safari5.1-6 *!
    background: linear-gradient(to bottom,  #e6e6e6 0%,#b3b3b3 140%) !important; !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6e6e6', endColorstr='#b3b3b3',GradientType=0 )  !important;; !* IE6-9 *!

}
.tr-first-viagem  input {
    color: white;
    font-size: 18px;
    font-style: italic;
    font-weight: 500;
}
.datepicker-days table > tbody > tr > td:first-child {
    background: transparent !important;
    color: black !important;
}

.datareceitas_fix.show {
    display: flex !important;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    max-width: 840px;
}


table.dd_ana > tbody > tr > td:first-child {
    background: transparent;
    color: black;
}

table.dd_ana table tbody > tr > td:first-child {
    background: transparent;
    color: black;
}
table.dd_ana td, .floating-table-coner td{
    padding: 6px 0;
    text-align: center;
    border-bottom: 1px solid #552cc6;
}
table.dd_ana  >thead:first-child>tr:first-child>th, .floating-table-coner thead:first-child>tr:first-child>th{
    font-size: 16px;
    font-size: 16px;
    font-weight: 400;
    border-bottom: 1px solid #552cc6;
    padding: 8px 0;

}
table.dd_ana  input {
    border: 0;
    background: transparent;
    font-size: 16px;
    text-align: center;
    font-weight: 300;
    width: 120px;
}
.accordion-btn.collapsed::after{
    content: none;
}
.data_col td {
    color: black !important;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.floating-table-coner {
    position: fixed;
    right: -400px;
    top: 120px;
    width: 360px;
    padding: 20px;
    background: white;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    box-shadow: -2px 4px 6px rgba(0, 0, 0, 0.2);
    transition: all 0.3s linear;
}

.floating-table-coner.active {
    right: 0;
}

.open-floating-table-coner {
    top: 40px;
    width: 20px;
    background: linear-gradient(45deg, #552cc6 0%, #be7bef 100%);
    position: fixed;
    right: 0;
    padding: 20px;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    box-shadow: -2px 4px 6px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.btn-primary {
    float: right;
}

.btn-secondary {
    background-color: rgba(141, 56, 226, 0.1);;
    color: black;
    padding: 6px 16px;
    border-radius: 20px;
    border: 0;
    font-size: 16px;
    cursor: pointer;
}

.btn-secondary.collapsed {
    padding: 6px 16px;
    border: 0;
    font-size: 16px;
    margin-right: 10px;
}

.btn-secondary.active {
    background-color: #552cc6;
    color: white;
}

.btn-secondary:focus {
    outline: none;
    color: white;
}

.collapse, .collapse.hide {
    display: none;
}

.collapse.show {
    display: block;
}

.objectivos_form input {
    padding: 6px 8px;
    border-radius: 20px;
    margin-top: 10px;
    border: 0;

}

.ojectivos-receitas-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.ojectivos-receitas-container p {
    flex-direction: column;
    display: flex;
    width: 28%;
    flex: 0 0 48%;
    margin: 5px 0;
}

.chart-left {
    width: calc(100% / 3);
    float: left;
}
.canvasjs-chart-credit{
    display: none !important;
}
.chart-row {
    display: flex;
    justify-content: space-around;
    background: white;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 40px;
    box-shadow: -2px 3px 5px rgba(0,0,0,0.2);
    flex-direction: column;
}
.admin-body {
    background: #f1f1f1;
}
.admin-body .content-container{
    background: #f1f1f1;
}
.admin-body #main-container{
    margin-top: 0 !important;
}
.section-chart {
    display: block;
    width: 100%;
    max-width: 600px;
}
.stacked-row {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.row-item{
    width: 100%;

}

.row-item div:first-child{
    background: linear-gradient(to bottom, #c33fff -35%, #7d00ff 120%);
    margin-top: 0px;
    font-weight: 600;
    font-size: 22px;
}
.sms-total-value {
    text-align: center;
    width: 100%;
    display: block;
    background: -webkit-linear-gradient(top, #ffe66a -5%, #c652df 200%);
    margin: 20px auto;
    color: white;
    max-width: 400px;
    font-style: italic;
    padding: 10px 18px;
    box-sizing: border-box;
    font-size: 28px;
    border-radius: 40px;
    text-transform: uppercase;
    font-weight: 300;
    box-shadow: -2px 3px 5px rgba(0,0,0,0.2)
}
.row-item div{
    background: linear-gradient(to bottom, #22b573 -35%, #006837 120%);
    padding: 20px;
    box-sizing: border-box;
    border-radius: 40px;
    color: white;
    text-transform: uppercase;
    text-align: center;
    margin-top: 10px;
    font-style: italic;
    box-shadow: -2px 3px 5px rgba(0,0,0,0.2)
}
.section-chart h2 {
    color: #9933ff;
    font-style: italic;
    font-size: 32px;
    font-weight: 600;
    text-transform: uppercase;
}
.total-value {
    color: #9933ff;
    font-style: italic;
    font-size: 18px;
    font-weight: 400;
    width: 100%;
    text-align: right;
}
.chart span {
    display: block;
    margin-top: 20px;
    max-width: 350px;
    width: 100%;
    text-align: center;
}

.chart-right {
    width: 50%;
    max-width: 450px;
    float: right;
}

.form-dd-total {
    position: absolute;
    top: 3.5%;
    right: 2%;
    display: block;
}

.form-dd-total input {
    border: 1px solid;
    padding: 4px 4px;
}

.form-dd-total button {
    position: relative;
    right: 0;
    bottom: 0;
    margin-left: 10px;
}

.lead_form .btn-primary {
    position: relative;
    right: 0;
    bottom: 0;
}

.autocomplete {
    position: relative;
}

.autocomplete .close {
    position: absolute;
    right: 4px;
    top: 0;
    cursor: pointer;
}

.dialog {
    height: 0;
    overflow: hidden;
}

.dialog.open {
    height: 200px;
    position: absolute;
    top: 34px;
    width: 100%;
    background: white;
    border: 1px solid black;
    overflow-y: scroll;
    text-align: center;
}

.dialog.open div {
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    background: rgba(0, 0, 0, 0);
    transition: all 0.3s linear;
}

.dialog.open div:hover {
    background: rgba(0, 0, 0, 0.1);
}

.red-call {
    background: #f62e0c;
    background: -moz-linear-gradient(top, #f62e0c 0%, #b62200 100%);
    background: -webkit-linear-gradient(top, #f62e0c 0%, #b62200 100%);
    background: linear-gradient(to bottom, #f62e0c 0%, #b62200 100%);
    color: white;
}

.green-call {

    background: #23bf00;
    background: -moz-linear-gradient(top, #87ff55 0%, #23bf00 100%);
    background: -webkit-linear-gradient(top, #87ff55 0%, #23bf00 100%);
    background: linear-gradient(to bottom, #87ff55 0%, #23bf00 100%);
    color: white;
}

.yellow-call {
    background: -moz-linear-gradient(top, #fff155 0%, #e9d700 100%);
    background: -webkit-linear-gradient(top, #fff155 0%, #e9d700 100%);
    background: linear-gradient(to bottom, #fff155 0%, #e9d700 100%);
    color: white;
}

.outros-call {
    background: -moz-linear-gradient(top, #c86aff 0%, #9a00e9 100%);
    background: -webkit-linear-gradient(top, #c86aff 0%, #9a00e9 100%);
    background: linear-gradient(to bottom, #c86aff 0%, #9a00e9 100%);
    color: white;
}
.to-call{
    background: -webkit-linear-gradient(top, #6ab6ff 20%, #3f86ff 100%);
    background: linear-gradient(to bottom, #6ab6ff 20%, #3f86ff 100%) !important;
    color: white;
}
.call-again{
    background: -moz-linear-gradient(top, #ffc42a 0%, #ffa415 100%);
    background: -webkit-linear-gradient(top, #ffc42a 0%, #ffa415 100%);
    background: linear-gradient(to bottom, #ffc42a 0%, #ffa415 100%);
    color: white;
}
tr {
    cursor: pointer;
}

.pop-up-leads, .pop-up-apoint, .pop-up-apoint-remove, .pop-up-apoint_nextmonth, .pop-up-apoint-remove_nextmonth, .pop-change-status {
    width: 100%;
    position: fixed;
    top: 2.5%;
    left: 0;
    height: 95%;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    display: none;
    box-sizing: border-box;
    z-index: 999999;
}

.pop-up-leads.active, .pop-up-apoint.active, .pop-up-apoint-remove.active, .pop-up-apoint_nextmonth.active, .pop-up-apoint-remove_nextmonth.active, .pop-change-status.active {
    display: flex;
    overflow: scroll;
}

.pop-up-leads form, .pop-up-apoint form, .pop-up-apoint-remove form, .pop-up-apoint_nextmonth form, .pop-up-apoint-remove_nextmonth form, .container-status {
    padding: 40px;
    box-sizing: border-box;
    background: linear-gradient(to bottom, #6ab6ff 20%, #3f86ff 100%) !important;
    width: 100%;
    max-width: 800px;
    border-radius: 40px;
    position: relative;
    overflow-y: scroll;
}

.container-status {
    width: 100%;
    max-width: 1280px;
}

.container-status form {
    width: 50%;
    display: block;
    text-align: center;
    display: flex;
    justify-content: center;

}

.close-form-pop, .close-form-pop-next-month {
    text-align: right;
    font-size: 2rem;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer
}
!*
.pop-up-leads form input, .pop-up-apoint form input, .pop-up-apoint_nextmonth form input {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    border: 1px solid black;
    margin-top: 20px;
}*!

.dates {
    display: flex;
    justify-content: space-between;
}
.pop-up-leads h3{
    display: block;
    line-height: 50px;
    border-bottom: 1px solid white;
    width: 100%;
}
.pop-up-leads h3 input {
    background: transparent;
    border: 0;
    padding: 10px 0;
    color: white;
    font-size: 24px;
    font-style: italic;
    box-sizing: border-box;
    display: block;
}
.pop-up-leads label {
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-style: italic;
    font-size: 16px;
    display: flex;
    position: relative;
    flex-direction: column;
}
.pop-up-leads input[type="date"], .pop-up-leads input[type="datetime-local"]{
    border: 0;
    padding: 6px 10px;
    border-radius: 20px;
    margin-top: 12px;
    font-family: Roboto;
    letter-spacing: 1px;
    font-weight: 300;
}
input.rela-inp {
    border: 0;
    padding: 6px 10px;
    border-radius: 20px;
    margin-top: 12px;
    font-family: Roboto;
    letter-spacing: 1px;
    font-weight: 300;
}
.pop-up-leads textarea {
    width: 100%;
    padding: 20px 30px;
    box-sizing: border-box;
    border: 0;
    border-radius: 20px;
    background: rgba(255,255,255,0.6);
    font-style: italic;
}
.edit-lead-contacto-cons input {
    background: transparent;
    border: 0;
    padding: 0px 8px;
    box-sizing: border-box;
    color: white;
    font-size: 18px;
    font-style: italic;
    font-weight: 200;
    width: 120px;
}
.status-edit-lead.edit-lead-contacto-cons label:last-child{
    width: 270px;
}
.status-edit-lead{
    position: relative;
    display: flex;
    line-height: 40px;
    margin-bottom: 20px;
    justify-content: space-between;
    flex-direction: column;
}

.status-edit-lead .select {
    position: relative;
    right: 0;
    left: 0;
    margin: 0;
    height: 40px;
    width: 200px;
    float: none;
}

.status-edit-lead label:last-child .select{
    width: 150px;
}
.status-edit-lead label:last-child{
    width: 220px;
}
.status-edit-lead  .option, .status-edit-lead  .shown{
    height: 40px;
    font-size: 14px;
}
.fix-height{
    display: flex;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-style: italic;
    font-size: 18px;
    position: relative;
    width: 335px;
}
.add_new_lead, .add_new_grupo {
    background: transparent;
    border: 0;
    font-size: 1.2rem;
    color: white;
    display: flex;
    margin: 0 auto;
    line-height: 1.2rem;
    text-transform: uppercase;
    padding: 12px 14px;
}

.pop-btn-save {
    background: #ffcc00;
    border: 0;
    padding: 8px 22px;
    color: white;
    font-style: italic;
    font-size: 22px;
    position: relative;
    cursor: pointer;
    border-radius: 20px;
    box-shadow: -2px 4px 6px rgba(0, 0, 0, 0.2);
}

.pop-btn-remove {
    background: red;
    border: 0;
    padding: 8px 16px;
    color: white;
    font-size: 18px;
    float: right;
    border-radius: 20px;
    margin: 0 20px;
}

.delete-row {
    padding: 6px 2px;
    background: transparent;
    border: 0;
}

.grps-table > tbody > tr:first-child > td:first-child {
    background: transparent;
}

.grps-table thead tr:hover {
    background: white;
}

.grupo-form {
    border: 2px solid #552cc6;
    position: relative;
    display: block;
    padding: 20px;
    box-sizing: border-box;
}

.grupo-form h4 {
    margin-top: 0;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: 20px;
    color: #552cc6;
}

.grupo-form input {
    padding: 10px;
    font-size: 1rem;
    !* margin-bottom: 40px; *!
    border: 1px solid #552cc6;
    !* color: #552cc6; *!
}

table.rota-pt > tbody > tr > td {
    padding: 8px 0;
}

table.rota-pt > tbody > tr:first-child > td:first-child {
    background: #552cc6;
    color: white;
}

table.rota-pt > tbody > tr > td.active {
    background: yellow;
}

table.rota-pt > tbody > tr > td span {
    background: darkgrey;
    color: white;
    width: 100%;
    display: block;
    position: relative;
    height: 100%;
    text-transform: uppercase;
}

table.rota-pt > tbody > tr > td span p {
    opacity: 0;
    background: black;
    color: white;
    top: -100px;
    left: -100%;
    padding: 12px;
    width: 260px;
    position: absolute;
    box-sizing: border-box;
    transition: all 0.3s linear;
    visibility: hidden;
}

table.rota-pt > tbody > tr > td:hover span p {
    opacity: 1;
    visibility: visible;
}

table.rota-pt > tbody > tr > td[data-id-info] {
    padding: 0;
    line-height: 2rem;
    opacity: 0.7;
}

.calendar-container-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #552cc6;
    color: white;
    margin-top: 20px;
    padding: 20px 40px 20px 40px;
}

.left-date {
    font-size: 1rem;
    text-transform: uppercase;
    margin-right: 20px;
    cursor: pointer;
}

.right-date {
    font-size: 1rem;
    text-transform: uppercase;
    margin-left: 20px;
    cursor: pointer;
}

.calendar-container-data span {
    text-align: center;
}

.calendar-container-data span h2, .calendar-container-data span h3 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 400;
}

.active-ai {
    background: darkgrey;
    color: white;
    opacity: 0.8;
}

.calendars-container {
    overflow: hidden;
    position: relative;

}

#container-table-rota {
    position: absolute;
    top: 145px;
    left: 0;
    z-index: 0;
    opacity: 0;
    transition: all 0.3s linear;
    background: #f6f9fa;
    margin-top: 20px;
    padding: 40px 40px 80px 40px;
    box-sizing: border-box;
    width: 100%;
}

#container-table-rota.active {
    position: absolute;
    opacity: 1;
    z-index: 1;
}

.add-conte {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.add_apoint, .add_apoint_nextmonth {
    background: #552cc6;
    color: white;
    font-size: 2rem;
    padding: 10px;
    line-height: 1.2rem;
    box-sizing: border-box;
}

input#next_month {
    display: none;
}

.pop-up-apoint form, .pop-up-apoint-remove form, .pop-up-apoint_nextmonth form, .pop-up-apoint-remove_nextmonth form {
    height: 50%;
}

.pop-up-apoint form input, .pop-up-apoint_nextmonth form input {
    display: inline-block;
    width: auto;
}

.pop-up-apoint-remove form label, .pop-up-apoint-remove_nextmonth form label {
    font-size: 1rem;
    font-family: Roboto;
}

.pop-up-apoint-remove form input, .pop-up-apoint-remove_nextmonth form input {

}

.pop-up-apoint-remove form select, .pop-up-apoint-remove_nextmonth form select {
    font-size: 1rem;
    margin-top: 10px;
}

.close-form-pop-remove {
    font-size: 1.5rem;
    font-family: Roboto;
    text-align: right;
    cursor: pointer;
}

.form-remove-pt {
    display: none;
    width: 100%;
    height: auto !important;
    padding-top: 0 !important;
}

#next_month_apoiat {
    display: none;
}

.active-ai.realizad, .active-ai.realizad > span {
    background: green;
}

.active-ai.nrealizad, .active-ai.nrealizad > span {
    background: red;
}

.inscrito-call {
    background: palevioletred;
}

.marcado-call {
    background: cornflowerblue;
}

.naotend-call {
    background: salmon;
}

a.logout-btn {
    position: absolute;
    bottom: 20px;
}

.alert-danger {
    display: inline-block;
    margin-bottom: 20px;
    font-style: italic;
    color: #552cc6;
    border-bottom: 1px solid #552cc6;
}

!* ===== Horizontal Rule ===== *!
.rule {
    margin: 10px 0;
    border: none;
    height: 1.5px;
    background-image: linear-gradient(left, #f0f0f0, #c9bbae, #f0f0f0);
}

!* ===== Select Box ===== *!
.sel {
    font-size: 1rem;
    display: inline-block;
    margin: 3em 2em;
    width: 200px;
    background-color: transparent;
    position: relative;
    cursor: pointer;
}

!*
.sel::before {
    position: absolute;
    content: '\f063';
    font-family: 'FontAwesome';
    font-size: 14px;
    color: black;
    right: 20px;
    top: calc(50% - 0.5em);
}
*!

.sel.active::before {
    transform: rotateX(-180deg);
}

.sel__placeholder {
    display: block;
    font-family: Roboto;
    font-weight: 300;
    font-size: 16px;
    border: 0;
    padding: 6px 0;
    text-align: left;
    pointer-events: none;
    user-select: none;
    visibility: visible;
}

.sel.active .sel__placeholder {
    visibility: hidden;
}

.sel__placeholder::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.2em 0.5em;
    content: attr(data-placeholder);
    visibility: hidden;
}

.sel.active .sel__placeholder::before {
    visibility: visible;
}

.sel__box {
    position: absolute;
    top: calc(100% + 4px);
    left: -4px;
    display: none !important;
    list-style-type: none;
    text-align: left;
    font-size: 1em;
    background-color: #FFF;
    width: calc(100% + 8px);
    box-sizing: border-box;
}

.sel.active .sel__box {
    display: block !important;
    animation: fadeInUp 500ms;
    border-right: 1px solid black;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
}

.sel__box__options {
    display: list-item;
    font-family: Roboto;
    font-size: 14px;
    color: black;
    padding: 0.5em 1em;
    user-select: none;
}

.sel__box__options::after {
    content: '\f00c';
    font-family: 'FontAwesome';
    font-size: 0.5em;
    margin-left: 5px;
    display: none;
}

.sel__box__options.selected::after {
    display: inline;
}

.sel__box__options:hover {
    background-color: #ebedef;
}

!* ----- Select Box Black Panther ----- *!
.sel {
    border-bottom: 1px solid rgba(0, 0, 0, 1);
}

.sel--black-panther {
    z-index: 3;
    display: inline-block !important;
    margin: 0;
    margin-top: 10px;
    border: 0;
}

!* ----- Select Box Superman ----- *!
.sel--superman {
    !*   display: none; *!
    z-index: 2;
}

!* ===== Keyframes ===== *!
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 20px, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.funcionarios-table,.viagem-socio-table {
    background: white;
    border-radius: 20px;
    box-sizing: border-box;
    max-width: 900px;
    border-spacing: 0 10px;
    margin-top: 20px;
}
.add-button-plus-gradient{
    display: block;
    position: fixed;
    bottom: 20px;
    right: calc(50% - 24px);
    background: #ffe66a;
    background: -moz-linear-gradient(top, #ffe66a 0%, #c652df 100%);
    background: -webkit-linear-gradient(top, #ffe66a 0%, #c652df 100%);
    background: linear-gradient(to bottom, #ffe66a 0%, #c652df 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe66a', endColorstr='#c652df', GradientType=0);
    width: 24px;
    height: 16px;
    cursor: pointer;
    z-index: 9999;
    padding: 16px 12px;
    border-radius: 100%;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
    transition: all 0.3s linear;
}


.model-lead-new{
    position: fixed;
    top: 0;
    z-index: 99999;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.2);
}
.model-lead-new.active {
    display: flex;
}
.mobile-add-lead-pop{
    position: relative; margin-top: 100px
}
.mobile-add-lead-pop label{
    color: white;
    font-style: italic;
    margin-bottom: 10px;
    justify-content: space-between;
    display: flex;
}
.mobile-add-lead-pop input{
    background: transparent;
    border: 0;
    border-bottom: 1px solid white;
    width: 60%;
    color: white;
}
.model-lead-new form{
    background: #6ab6ff;
    background: -moz-linear-gradient(left, #6ab6ff 0%, #3f86ff 100%);
    background: -webkit-linear-gradient(left, #6ab6ff 0%,#3f86ff 100%);
    background: linear-gradient(to right, #6ab6ff 0%,#3f86ff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6ab6ff', endColorstr='#3f86ff',GradientType=1 );
    height: auto;
    border-radius: 40px;
    width: 90%;
    padding: 20px 20px;
    box-sizing: border-box;
    position: relative;
    padding-bottom: 80px;
}
.close-model-lead-new{
    float: right;
}
.model-socio{
    position: absolute;
    top: 0;
    z-index: 99999;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.2);
    display: none;
    justify-content: center;
    align-items: center;
}
.model-socio.active {
    display: flex;
}
.model-viagem-socio {
    position: absolute;
    top: 0;
    z-index: 99999;
    left: 0;
    width: 100%;
    height: 1600px;
    display: none;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.2);
}
.model-viagem-socio.active {
    display: flex;
}
.model-socio form{
    !* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#6ab6ff+0,3f86ff+100 *!
    background: #6ab6ff; !* Old browsers *!
    background: -moz-linear-gradient(left,  #6ab6ff 0%, #3f86ff 100%); !* FF3.6-15 *!
    background: -webkit-linear-gradient(left,  #6ab6ff 0%,#3f86ff 100%); !* Chrome10-25,Safari5.1-6 *!
    background: linear-gradient(to right,  #6ab6ff 0%,#3f86ff 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6ab6ff', endColorstr='#3f86ff',GradientType=1 ); !* IE6-9 *!
    height: 50%;
    border-radius: 40px;
    width: 90%;
    padding: 20px 20px;
    box-sizing: border-box;
    position: relative;
}
.model-viagem-socio form{
    !* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#6ab6ff+0,3f86ff+100 *!
    background: #662d91; !* Old browsers *!
    background: -moz-linear-gradient(left,  #93278f 0%, #662d91 100%); !* FF3.6-15 *!
    background: -webkit-linear-gradient(left,  #93278f 0%,#662d91 100%); !* Chrome10-25,Safari5.1-6 *!
    background: linear-gradient(to right,  #93278f 0%,#662d91 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6ab6ff', endColorstr='#3f86ff',GradientType=1 ); !* IE6-9 *!
    height: auto;
    border-radius: 40px;
    width: 100%;
    padding: 20px 20px;
    box-sizing: border-box;
    position: relative;
}
.close-model-socio,.close-model-viagem-socio {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}
.form-viagem-socio .marcacao-row{
    margin-bottom: 40px;
    margin-top: 40px;
}
.form-viagem-socio .marcacao-row label {
    margin-bottom: 10px;
}
.close-model-socio i, .close-model-viagem-socio i {
    width: 32px;
    height: 32px;
}
.model-socio form .content-form{
    margin-top: 60px;
}
.model-socio form .content-form p {
    -webkit-display: flex;
    -moz-display: flex;
    -ms-display: flex;
    -o-display: flex;
    -khtml-display: flex;
    display: flex;
}
.model-socio form .content-form label , .model-viagem-socio form label{
    color: white;
    font-style: italic;
    margin-bottom: 10px;
    min-width: 60px;
}
.model-socio form .content-form input {
    background: transparent;
    border: 0;
    border-bottom: 1px solid white;
    width: 100%;
}
.alertas-viagems {
    background: white;
    border-radius: 20px;
    box-sizing: border-box;
    max-width: 900px;
    border-spacing: 0 10px;
    margin-top: 20px;
    border-spacing: 10px;
}
.alertas-viagems th {
    font-size: 20px;
    font-weight: 500;
    padding: 8px 0;
    color: #cc66cc;
    font-style: italic;
}
.alertas-viagems tr:nth-child(even) td{
    background: #fcee21;
    background: -moz-linear-gradient(top, #fcee21 0%, #f89d1e 100%);
    background: -webkit-linear-gradient(top, #fcee21 0%, #f89d1e 100%);
    background: linear-gradient(to bottom, #fcee21 0%, #f89d1e 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcee21', endColorstr='#f89d1e', GradientType=0);
}
.alertas-viagems tr:nth-child(odd) td{
    background: #c69c6d;
    background: -moz-linear-gradient(top, #c69c6d 0%, #754c24 100%);
    background: -webkit-linear-gradient(top, #c69c6d 0%, #754c24 100%);
    background: linear-gradient(to bottom, #c69c6d 0%, #754c24 100%);
}
.alertas-viagems td{
    padding: 6px 0;
    text-align: center;
    border-bottom: 1px solid transparent!important; ;
    border-radius: 20px !important;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;;
    border-top-right-radius: 0 !important;;
    color: white;
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
}
.alertas-viagems td:first-child{
    border-radius: 20px;
    border-bottom-right-radius: 0 !important;;
    border-top-right-radius: 0 !important;;
    border-top-left-radius: 20px !important;;
    border-bottom-left-radius: 20px !important;;
}
.alertas-viagems td:last-child{
    background: -webkit-linear-gradient(top, #c33fff 20%, #7d00ff 100%);
    background: linear-gradient(to bottom, #c33fff 20%, #7d00ff 100%) !important;
    border-bottom-right-radius: 20px  !important;;
    border-top-right-radius: 20px  !important;;
}


!*table.funcionarios-table>tbody>tr>td:first-child{
    color: #552cc6;
    background: white;
}*!
table.viagem-socio-table > tbody > tr > td {
    padding: 2px 10px;
}
table.viagem-socio-table > tbody > tr > td:last-child {
    width: 40px;
}
table.viagem-socio-table > tbody > tr > td:first-child{
    width: 70px;
}

td.hide, .tr-first-viagem, th.hide, .pos-table-first-row{
    display: none;
}

.viagem-socio {
    flex-direction: column !important;
    width: 80%;

    margin-bottom: 40px;
    margin-top: 40px;
    position: relative;
    padding: 10px 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
div.viagem-socio label{
    display: flex;
    flex-direction: column;

}
p.viagem-socio{
    flex-direction: column !important;
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: space-around;
    align-items: flex-start;
}
.viagem-socio input {
    margin: 0;
}

.viagem-socio label {
    margin-right: 40px;

}

.viagem-socio span label {
    margin-right: 10px;
    width: 105px;
}


.search-default {
    width: 100%;
    background: #fcee21;
    background: -moz-linear-gradient(top, #fcee21 0%, #f89d1e 100%);
    background: -webkit-linear-gradient(top, #ffe66a -5%, #c652df 200%);
    padding: 10px 20px;
    border-radius: 20px;
    margin-left: 8px;
    max-width: 900px;
    position: relative;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    background: -webkit-linear-gradient(top, #c33fff 20%, #7d00ff 100%);
}
.search-default .select{
    left: 105px;
    top: 8px;
}
.search-default input.active{
    display: block;height: 40px;
}
.search-default input.hidden{
    display: none;
}
.search-default  div > p {
    display: flex;
    flex-direction: column;
}
.search-default .first-row{
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    flex-direction: column;
}
.search-default label {
    color: white;
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    margin-right: 10px;
    width: 225px;
}
.search-default input {
    border-radius: 20px;
    border: 0;
    padding: 8px 16px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 6px;
}
.search-default button {
    position: relative;
    margin-top: 20px;
    width: 100%;
    max-width: none;
    right: 0;
}
p.viagem-socio {
    width: 100% !important;
    padding: 10px 20px;
    box-sizing: border-box;
    border-radius: 30px;
    margin-bottom: 0;
    margin-top: 0;
}

p.viagem-socio:hover {
    background: rgba(0, 0, 0, 0.02);
}
.modal-socio-viagem-nome{
    border-bottom: 1px solid white;
    padding-bottom: 20px;
    padding-top: 40px;
    box-sizing: border-box;
}
.modal-socio-viagem-nome input {
    margin: 0 auto;
    display: block;
    position: relative;
    font-size: 22px;
    background: transparent;
    border: 0;
    text-transform: uppercase;
    color: white;
    text-align: center;
}
.modal-socio-viagem-nome img {
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
}
!* Customize the label (the container) *!
.container-check {
    display: block;
    position: relative;
    padding-right: 35px;
    cursor: pointer;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

!* Hide the browser's default checkbox *!
.container-check input {
    position: absolute;
    right: 0;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

!* Create a custom checkbox *!
.checkmark {
    position: absolute;
    top: 0;
    right: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}

!* On mouse-over, add a grey background color *!
.container-check:hover input ~ .checkmark {
    background-color: #ccc;
}

!* When the checkbox is checked, add a blue background *!
.container-check input:checked ~ .checkmark {
    background-color: #2196F3;
    background: linear-gradient(45deg, #be7bef 0%, #552cc6 100%);
}

!* Create the checkmark/indicator (hidden when not checked) *!
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

!* Show the checkmark when checked *!
.container-check input:checked ~ .checkmark:after {
    display: block;
}

!* Style the checkmark/indicator *!
.container-check .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.loader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999;
    top: 0;
    display: none;
    background: -webkit-linear-gradient(45deg, rgba(190, 123, 239, 0.6) 0%, #552cc6 100%);
    background: linear-gradient(45deg, rgba(190, 123, 239, 0.6) 0%, #552cc6 100%);
}

.loader.active {
    display: flex;
    justify-content: center;
    align-items: center;
}

@-webkit-keyframes rotating !* Safari and Chrome *!
{
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.fae-spinner {
    width: 80px !important;
    height: 80px !important;
    background: url(/spinner-light.svg) no-repeat;
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}

.ojectivos-receitas-container p label {
    width: 150px;
    display: inline-block;
    color: white;
    font-style: italic;
}
.datepicker.dropdown-menu {
    top: 180px !important;
}





.h40 {
    height: 40px;
}

.clear {
    clear: both;
}
.select {
    width: 200px;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    color: #ffffff;
    font-weight: 300;
    float: left;
    z-index: 1;
}
.select:before {
    content: '';
    position: absolute;
    background-color: #ffffff;
    width: 14px;
    height: 14px;
    -webkit-transform: rotate(45deg);
    top: 15px;
    right: 15px;
}
.select:after {
    content: '';
    position: absolute;
    background: linear-gradient(to bottom, #fcee21 -75%, #f89d1e 260%);
    width: 14px;
    height: 14px;
    -webkit-transform: rotate(45deg);
    top: 20px;
    right: 15px;
}
.collapsed {
    height: 40px;
}
.collapsed:before {
    content: '';
    position: absolute;
    background-color: #ffffff;
    width: 14px;
    height: 14px;
    -webkit-transform: rotate(45deg);
    top: 10px;
    right: 15px;
}
.collapsed:after {
    content: '';
    position: absolute;
    background: linear-gradient(to bottom, #fcee21 -25%, #f89d1e 260%);
    width: 14px;
    height: 14px;
    -webkit-transform: rotate(45deg);
    top: 5px;
    right: 15px;
}
.option,
.shown {
    width: 300px;
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    cursor: pointer;
    background: linear-gradient(to bottom, #fcee21 -46%, #f89d1e 217%);
}

.shown {
    background-color: transparent;
    font-weight: 400;
}
.styled-select-container {
    position: relative;
    float: left;
    border-radius: 3px;
    overflow: hidden;
    height: 40px;
    width: 300px;
    margin: 10px;
    background-color: #1ABC9C;
}
.styled-select-container:before {
    content: '';
    position: absolute;
    z-index: 1;
    background-color: #ffffff;
    width: 14px;
    height: 14px;
    -webkit-transform: rotate(45deg);
    top: 10px;
    right: 15px;
}
.styled-select-container:after {
    content: '';
    position: absolute;
    z-index: 1;
    background-color: #1ABC9C;
    width: 14px;
    height: 14px;
    -webkit-transform: rotate(45deg);
    top: 5px;
    right: 15px;
}
.styled-select {
    position: relative;
    z-index: 2;
    background: transparent;
    -webkit-appearance: none;
    border: 0;
    height: 40px;
    width: 100%;
    text-indent: 15px;
    font-size: 16px;
    color: #ffffff;
    outline: none;
}
.pos-table {
    border-spacing: 10px;
    max-width: 900px;
}
table.pos-table > thead:first-child > tr:first-child > th {
    font-size: 20px;
    font-weight: 500;
    padding: 8px 0;
    color: #cc66cc;
    font-style: italic;
    text-transform: uppercase;
}

table.pos-table > tbody > tr > td:first-child {
    border-radius: 20px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

    background: -webkit-linear-gradient(top, #6ab6ff 20%, #3f86ff 100%);
    background: linear-gradient(to bottom, #6ab6ff 20%, #3f86ff 100%) !important;
    color: white;

}
table.pos-table > tbody > tr:first-child > td:first-child{
    background: transparent;
}
table.pos-table > tbody > tr > td:last-child{
    border-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 45px;
    background: -webkit-linear-gradient(top, #c33fff 20%, #7d00ff 100%);
    background: linear-gradient(to bottom, #c33fff 20%, #7d00ff 100%) !important;
}

table.pos-table > tbody > tr > td {
    padding: 0px 10px;
    text-align: center;
    font-size: 14px;
    font-style: italic;
}
.legend-btn {
    border: 0;
    background: -webkit-linear-gradient(top, #ffe66a -5%, #c652df 200%);
    border-radius: 20px;
    color: white;
    font-size: 18px;
    font-style: italic;
    padding: 4px 12px;
}
.modal-legenda{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0px;
    z-index: 99999;
    display: none;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.2);
    padding: 0 20px;
    box-sizing: border-box;
}
.modal-legenda.active {
    display: flex;
}
.content-legenda {
    background: -webkit-linear-gradient(top, #ffe66a -5%, #c652df 200%);
    padding: 40px;
    border-radius: 20px;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    max-width: 500px;
}
.legend-btn-close{
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}
.content-legenda h3 {
    font-size: 28px;
    color: white;
    font-style: italic;
    font-weight: 400;
    width: auto;
    border-bottom: 1px solid white;
    display: block;
    position: relative;
    padding-bottom: 5px;
    width: 160px;
    margin-top: 0;
    text-transform: uppercase;
}
.modal-legenda .content-form div:not(:last-child){
    margin-bottom: 20px;

}
.modal-legenda .content-form div p {
    margin: 0;
    font-size: 12px;
    color: white;
}
.pos-table-first-row {
    background: #e6e6e6 !important;
    background: -moz-linear-gradient(top, #e6e6e6 0%, #b3b3b3 140%) !important;
    background: -webkit-linear-gradient(top, #e6e6e6 0%,#b3b3b3 140%) !important;
    background: linear-gradient(to bottom, #e6e6e6 0%,#b3b3b3 140%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6e6e6', endColorstr='#b3b3b3',GradientType=0 ) !important;
}

.pos-table-first-row  input {
    border: 0;
    background: transparent;
    font-size: 16px;
    text-align: center;
    font-weight: 300;
    width: 120px;
    margin: 0 auto;
    display: block;
}
.pos-table-first-row .select {
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
    color: #ffffff;
    font-weight: 300;
    float: left;
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    height: 50px;
}
.pos-table-first-row .option, .pos-table-first-row .shown{
    width: 100%;
    height: 50px;
    padding-left: 0;
    line-height: 50px;
}
.edit-lead-contacto-cons label:first-child{
    flex-direction: row;
}


.top-info{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.top-info-column{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.top-info .top-info-column:last-child{
    display: none;
}
.user-info-item{
    background: -webkit-linear-gradient(top, #ffe66a -5%, #c652df 200%);
    border-radius: 40px;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    position: relative;
}
.user-info-item h2{
    color: white;
    position: relative;
    text-transform: uppercase;
    margin: 20px 0 0 0;
    letter-spacing: 1px;
}
.user-info-item .top-info-date {
    color: white;
    position: relative;
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
}
.date-item-column{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 49%;
    background: -webkit-linear-gradient(top, #c33fff 0%, #7d00ff 120%);
    background: linear-gradient(to bottom, #c33fff 0%, #7d00ff 120%);
    padding: 20px;
    box-sizing: border-box;
    border-radius: 40px;
}
.resume-day {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
}
.date-item-column h2{
    color: white;
    font-size: 32px;
    margin: 0;
    margin-bottom: 10px;
}
.date-item-column span {
    color: white;
    position: relative;
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
}
.date-change-top-info {
    display: flex;
    height: 50px;
    width: 100%;
    position: relative;
    justify-content: space-between;
}
.date-change-top-info a {
    color: #cc66cc;
    text-decoration: none;
    font-size: 12px;
    font-style: italic;
    line-height: 40px;
}
.resumo-week-info{
    background: -webkit-linear-gradient(top, #c33fff 0%, #7d00ff 150%);
    background: linear-gradient(to bottom, #c33fff 0%, #7d00ff 150%);
    padding: 12px 20px;
    box-sizing: border-box;
    border-radius: 20px;
    color: white;
}
.agenda-title{
    width: 100%;
    position: relative;
    padding: 14px 20px;
    box-sizing: border-box;
    background: -webkit-linear-gradient(top, #c33fff 0%, #7d00ff 150%);
    background: linear-gradient(to bottom, #c33fff 50%, #7d00ff 180%);
    text-align: center;
    margin: 40px 0;
    color: white;
    font-weight: 600;
    font-style: italic;
    font-size: 18px;
    letter-spacing: 1.5px;
    height: 80px;
}
.agenda-title a {
    position: absolute;
    left: 20px;
    text-decoration: none;
    font-size: 14px;
    color: white;
    bottom: 20px;
}
.agenda-title a:last-child{
    right: 20px;
    left: inherit;

}
.agenda-comercial{
    position: relative;
}
.agenda-comercial-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 400px;
    padding-bottom: 60px;
}
.form-agenda-comercial{
    background: #6ab6ff;
    background: -moz-linear-gradient(left, #6ab6ff 0%, #3f86ff 100%);
    background: -webkit-linear-gradient(left, #6ab6ff 0%,#3f86ff 100%);
    background: linear-gradient(to right, #6ab6ff 0%,#3f86ff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6ab6ff', endColorstr='#3f86ff',GradientType=1 );
    height: 100%;
    border-radius: 20px;
    width: 100%;
    padding: 20px 20px;
    box-sizing: border-box;
    position: relative;
    padding-bottom: 60px;
    z-index: 9999;
}
.agenda-column {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.model-agenda-comercial,.model-agenda-comercial-edit, .model-agenda-comercial-status-report{
    display: none;
    position: fixed;
    bottom: 20px;
    right: 0;
    width: 90%;
    height: auto;
    z-index: 99999999;
}
.model-agenda-comercial.active,.model-agenda-comercial-edit.active , .model-agenda-comercial-status-report.active{
    display: block;
}
.model-agenda-comercial-status-report .content-form-agenda{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
    background:  -webkit-linear-gradient(top, #ffe66a -5%, #fbb03b 100%);
    padding: 50px;
    box-sizing: border-box;
    border-radius: 40px;
}

.model-agenda-comercial-status-report .content-form-agenda button {
    position: relative;
    float: none;
    margin-bottom: 20px;
    bottom: 0;
    right: 0;
    left: 0;
}
.agenda-column h3 {
    color:  #cc66cc;
    font-style: italic;
    font-size: 20px;
    text-align: center;margin-top: 0;
}
.agenda-item,.agenda-marcacao {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-radius: 20px;
    background: linear-gradient(to bottom, #6ab6ff 20%, #3f86ff 100%) !important;
    color: white;
    padding: 0;
    box-sizing: border-box;
    font-style: italic;
    cursor: pointer;
    margin-bottom: 10px;
}
.agenda-marcacao{
    background: linear-gradient(to bottom, #c33fff 50%, #7d00ff 180%) !important;
}
.agenda-item:hover .type-title ,.agenda-marcacao:hover .type-title{
    opacity: 1;
}
.agenda-item:hover .name-title ,.agenda-marcacao:hover .name-title{
    opacity: 0
}
.agenda-marcacao.hide{
    display: none;
}
.agenda-item .time,.agenda-marcacao .time{
    background: transparent;
    border-radius: 20px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding: 10px 6px;
    box-sizing: border-box;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    width: 68px;
}
.time.walkin{
    background: black;
}
.time.app-quit{
    background: #ff0000;
}
.time.app-leads{
    background: #cccc00;
}
.time.app-pos{
    background: #33ccff;
}
.time.app-prosp{
    background: #33cc00;
}
.time.beback{
    background: #ff9900;
}

.name-type{
    position: relative;
    display: block;
    !*width: 140px;*!
    max-height: 42px;
    width: 100%;
    text-align: center;
    transition: all 0.3s linear;
}
.name-title {
    line-height: 14px;
    transition: all 0.3s linear;
    text-transform: uppercase;
    font-size: 14px;
}
#rota_pt_container .name-title{
    line-height: 42px;
}
.type-title{
    opacity: 0;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    top: 10px;
    right: 0;
    width: 100%;
    line-height: 24px;
    height: 42px;
    overflow-x: hidden;
    overflow-y: scroll;
}
.settings-tools{
    position: relative;
    width: 45px;
    transition: all 0.3s linear;
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    background: #cc66cc;
    text-align: center;
}
.agenda-comercial .add-button-plus-gradient{
    right: calc(50% - 26px);
}
.type-marcacao{
    width: 40px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 60px;
    box-sizing: border-box;
    position: absolute;
    overflow: hidden;
    left: 0;
    top: 0;
    background: #cc33ff;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    transition: all 0.3s linear;
    z-index: 2;
}
.type-marcacao.type-walkin{
    background: black;
}
.type-marcacao.type-desistencia{
    background: #ff0000;
}
.type-marcacao.type-leads{
    background: #cccc00;
}
.type-marcacao.type-pos{
    background: #33ccff;
}
.type-marcacao.type-prospect{
    background: #33cc00;
}
.type-marcacao.type-baback{
    background: #ff9900;
}
.type-marcacao.active {
    width: 85%;
}
.content-type-marcacao {
    background: white;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 95%;
    width: 95%;
    margin-left: 50px;
    border-radius: 20px;
    box-shadow: 4px 4px 12px rgba(0,0,0,0.4);

}
.type-marcacao.active  .content-type-marcacao{
    margin-left: 0px;
}
.content-type-marcacao label {
    color: #cc33ff;
    margin: 10px 0;
    font-size: 14px;
    align-items: center;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    font-style: italic;

}
.content-type-marcacao .line {
    height: 2px;
    background: rgba(0,0,0,0.5);
    flex: 1 0 0;
    margin-left: 15px;
    margin-right: 10px;
}
.content-form-agenda{
    width: calc(100% - 60px );
    margin-left: 60px;
    padding: 20px 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
.marcacao-row{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.content-form-agenda p {
    width: 100%;
    border-bottom: 1px solid rgba(255,255,255,1);
    padding-bottom: 6px;
    display: flex;
    flex-direction: column;
}
.content-form-agenda p input {
    flex: 1 0 0;
    background: transparent;
    border: 0;
    padding: 4px 0px;
    font-style: italic;
    font-size: 18px;
    color: white;
}
.content-form-agenda p input::-webkit-input-placeholder { !* Chrome/Opera/Safari *!
    color: white;
}
.content-form-agenda p input::-moz-placeholder { !* Firefox 19+ *!
    color: white;
}
.content-form-agenda p input:-ms-input-placeholder { !* IE 10+ *!
    color: white;
}
.content-form-agenda p input:-moz-placeholder { !* Firefox 18- *!
    color: white;
}
.content-form-agenda label{
    color: white;
    font-size: 16px;
    font-style: italic;
    font-weight: 300;
}
.marcacao-form{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}
.marcacao-form label {
    width: 100%;
    display: flex;
    margin-bottom: 15px;
    text-transform: initial;
    letter-spacing: 0px;
    line-height: 30px;
    flex-direction: column;
}
.marcacao-form label input {
    order: 0;
    flex: 1 0 0;
    border-radius: 20px;
    background: white;
    padding: 10px;
    border: 0;
    box-sizing: border-box;
}
.marcacao-form  .fix-height{
    line-height: 40px;
}
.marcacao-form .select{
    margin: 0px;
    height: 40px;
}
.marcacao-form input[type="datetime-local"] {
    border: 0;
    padding: 6px 10px;
    border-radius: 20px;
    font-family: Roboto;
    letter-spacing: 1px;
    font-weight: 300;
    margin-left: 0px;
    margin-top: 0;
    background: white;
    flex: none;
}
.close-model-agenda,.close-model-agenda-status-report{
    position: absolute;
    right: 20px;
    cursor: pointer;
}
.close-model-agenda-status-report{
    top: 20px;
}
.marcacao-form textarea{
    border-radius: 20px;
    width: 100%;
    display: block;
    position: relative;
    border: 0;
    margin-top: 10px;
    padding:20px;
    box-sizing: border-box;

}
#user-info-item #right-arrows{
    background: url(/arrow-right.svg);
    width: 20px;
    display: block;
    height: 40px;
    background-repeat: no-repeat;
}
#user-info-item #left-arrows{
    background: url(/arrow-left.svg);
    width: 20px;
    display: block;
    height: 40px;
    background-repeat: no-repeat;
}
#next-user{
    position: absolute;
    right: 20px;
}
.form-users{
    background: linear-gradient(to bottom, #c33fff -35%, #7d00ff 120%);
    padding: 20px;
    box-sizing: border-box;
    border-radius: 20px;
    width: 100%;
    max-width: 840px;
    position: relative;
    box-shadow: -2px 4px 6px rgba(0, 0, 0, 0.2);
    padding-bottom: 60px;
}
.form-users label {
    width: 90px;
    display: inline-block;
    color: white;
    font-style: italic;
}
.form-users input {
    padding: 6px 8px;
    border-radius: 20px;
    margin-top: 10px;
    border: 0;
    width: 500px;
}
.form-users  h4 {
    color: white;
    font-weight: 500;
    display: inline-block;
    font-size: 28px;
    margin-bottom: 20px;
    margin-top: 20px;

}
#prev-user{
    position: absolute;
    left: 20px;
}
#rota_pt_container .user-info-item{
    background: -webkit-linear-gradient(top, #c33fff 0%, #7d00ff 120%);
    background: linear-gradient(to bottom, #c33fff 0%, #7d00ff 120%);
}
#rota_pt_container .date-item-column {
    width: 100%;
}
#rota_pt_container .agenda-title{
    padding: 0;
    display: flex;
    flex-direction: column;
}
#rota_pt_container .agenda-title button:first-child {
    width: 100%;
    margin: 0;
    display: inline-block;
    padding: 14px;
    border: 0;
    background: green;
    color: white;
    font-size: 20px;
    text-align: left;
    font-style: italic;
    font-weight: 600;
    background: linear-gradient(to bottom, #22b573 35%, #006837 180%);
    -webkit-transition: all 0.3s linear ;
    -moz-transition: all 0.3s linear ;
    -ms-transition: all 0.3s linear ;
    -o-transition: all 0.3s linear ;
    transition: all 0.3s linear ;
    cursor: pointer;
}

#rota_pt_container .agenda-title button:last-child{
    width: 100%;
    margin: 0;
    display: inline-block;
    padding: 14px;
    border: 0;
    color: white;
    font-size: 20px;
    text-align: left;
    font-style: italic;
    font-weight: 600;
    background: linear-gradient(to bottom, #c33fff 50%, #7d00ff 180%);
    -webkit-transition: all 0.3s linear ;
    -moz-transition: all 0.3s linear ;
    -ms-transition: all 0.3s linear ;
    -o-transition: all 0.3s linear ;
    transition: all 0.3s linear ;
    cursor: pointer;

}
.buttons-days{
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50px;
    width: 100%;
}
.buttons-days a{
    color: #cc66cc;
    text-decoration: none;
    font-size: 12px;
    font-style: italic;
    line-height: 40px;
}
#rota_pt_container .agenda-title button:focus{
    outline: none;
}
#rota_pt_container .form-agenda-comercial,#rota_pt_container  .agenda-item{
    background: -webkit-linear-gradient(top, #ffe66a -5%, #fbb03b 100%) !important;
}
#rota_pt_container .time{
    background: linear-gradient(to bottom, #6ab6ff 20%, #3f86ff 100%) ;
}
#rota_pt_container .content-form-agenda {
    width: calc(100%);
    margin-left: 0;
    flex-direction: column;
}
#rota_pt_container .content-form-agenda .normla-input input {
    flex: 1 0 0;
    background: transparent;
    border: 0;
    padding: 4px 0px;
    font-style: italic;
    font-size: 18px;
    color: white;
    border-bottom: 2px solid;
    margin-bottom: 20px;
}
#rota_pt_container .buttons-days{
    top: 100px;
}
#rota_pt_container .content-form-agenda label{
    font-size: 18px;
    display: block;
}
.search-default .select {
    width: 200px;
    border-radius: 4px;
    overflow: hidden;
    color: #ffffff;
    font-weight: 300;
    float: left;
    margin: 10px;
    position: absolute;
    left: 100px;
    top: 8px;
}
select {
    color: white;
}
.search-admin-form{
    height: 80px;
    justify-content: flex-start;
    align-items: center;
    max-width: 950px;
}
.search-admin-form label{
    width: auto;
}
.search-admin-form button {
    margin-top: 0;
    top: 0;
    bottom: 0;
    height: 40px;
    position: relative;
    right: 0;
}
.bootstrap-datetimepicker-widget{
    z-index: 9999999999999 !important;
    position: fixed !important;
}
.bootstrap-datetimepicker-widget table td.day{
    color: black !important;
    font-style: normal !important;
}
.datepicker table tr td.active.active, .datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled:hover.active, .datepicker table tr td.active.disabled:hover:active, .datepicker table tr td.active:active, .datepicker table tr td.active:hover.active, .datepicker table tr td.active:hover:active{
    color: white !important;
}
.bootstrap-datetimepicker-widget table thead tr:first-child th,.bootstrap-datetimepicker-widget .picker-switch td span, .bootstrap-datetimepicker-widget .timepicker-hour, .bootstrap-datetimepicker-widget .timepicker-minute, .bootstrap-datetimepicker-widget .timepicker-second, .bootstrap-datetimepicker-widget table td{
    color: black !important;
}
@media screen and (min-width: 768px) {
    .menu-container {
        display: flex;
    }

    .desktop-menu-ul {
        display: block;
    }

    .page-header {
        height: 100%;
        width: 100px;
    }

    .page-header .logo-bg {
        padding: 20px;
        box-sizing: border-box;
        width: 100%;
        max-width: 180px;
        display: inline-block;

    }

    .logo-mobile, .mobile-menu-ul {
        display: none;
    }

    #main-container {
        width: calc(100% - 100px);
        display: block;
        position: relative;
        margin-left: 100px;
        box-sizing: border-box;
    }

    .ojectivos-receitas-container p {
        flex: 0 0 30%;
    }
}
@media screen and (min-width: 1024px) {
    .page-header{
        position: fixed;
    }
    #rota_pt_container .content-form-agenda label{
        display: contents;
    }
    .chart-row {
        flex-direction: row;
    }
    .model-viagem-socio form{
        width: 60%;
    }
    #rota_pt_container .agenda-title button:first-child {
        border-bottom-right-radius: 50px;
    }
    #rota_pt_container .agenda-title{
        flex-direction: row;
    }
    #rota_pt_container .buttons-days{
        top: 50px;
    }
    #rota_pt_container .agenda-title button.active{
        width: 85%;
    }
    #rota_pt_container .agenda-title button{
        width: 40%;
    }
    .top-info{
        flex-direction: row;
    }
    .top-info-column{
        width: calc(95% / 3);
    }
    .top-info .top-info-column:last-child{
        display: flex;
    }
    .agenda-title{
        font-size: 24px;
    }
    .agenda-comercial-container {
        flex-direction: row;
    }
    .agenda-column {
        width: calc(95% / 7);
    }
    .tr-first-viagem, .pos-table-first-row {
        display: table-row;
    }

    td.hide , th.hide{
        display: table-cell;
    }
    .viagem-socio-table{
        border-spacing: 10px;
    }
    .add-button-plus-gradient{
        display: none;
    }
    table.viagem-socio-table > tbody > tr > td {
        font-size: 18px;
    }
    table.viagem-socio-table > tbody > tr > td {
        padding: 6px 10px;
    }
    .model-viagem-socio {
        left: 100px;
        width: calc(100% - 100px);
    }
    .viagem-socio {
        flex-direction: row !important;
        width: 100%;

    }
    p.viagem-socio {
        flex-direction: row !important;
        display: flex;
        align-items: center;
        justify-content: space-between;


    }
    .model-socio form .content-form label , .model-viagem-socio form label{
        margin-bottom: 0;
        margin-right: 10px;

    }
    .viagem-socio label {
        width: 150px;

    }
    .viagem-socio span {
        margin-right: 15px;
    }
    .select {
        width: 200px;
        border-radius: 4px;
        overflow: hidden;
        color: #ffffff;
        font-weight: 300;
        float: left;
        margin: 10px;
        position: absolute;
        left: 76px;
        top: 0;
        !* z-index: 1;*!
    }
    .search-default {
        flex-direction: row;
    }
    .search-default div > p {
        flex-direction: row;
    }
    .search-default input {
        border-radius: 20px;
        border: 0;
        padding: 8px 16px;
        width: auto;
        box-sizing: border-box;
        margin-top: 0px;
    }
    .search-default button {
        position: absolute;
        margin-top: 20px;
        width: auto;
        right: 20px;
    }
    table.pos-table > tbody > tr > td {

        font-size: 16px;

    }
    .modal-legenda .content-form div p{
        font-size: 16px;
    }
    .modal-legenda,.pop-up-leads, .pop-up-apoint, .pop-up-apoint-remove, .pop-up-apoint_nextmonth, .pop-up-apoint-remove_nextmonth, .pop-change-status{
        width: calc(100% - 100px) ;
        left: 100px;
    }

    .pop-up-leads, .pop-up-apoint, .pop-up-apoint-remove, .pop-up-apoint_nextmonth, .pop-up-apoint-remove_nextmonth, .pop-change-status {
        position: fixed;
        height: 100%;
        top:0;
        background: rgba(0, 0, 0, 0.2);
    }
    .pop-up-leads h3{
        width: 60%;
    }
    .status-edit-lead{
        flex-direction: row;
    }
    .status-edit-lead .select {
        position: absolute;
        left: initial;
        width: 255px;
    }
    .status-edit-lead  .option, .status-edit-lead  .shown{

        font-size: 16px;
    }
    .pop-up-leads label {
        flex-direction: row;
    }
    .pop-up-leads input[type="date"], .pop-up-leads input[type="datetime-local"]{
        margin-left: 10px;
        margin-top: 0;
    }
    .pop-up-leads textarea {
        border-radius: 40px;
    }
    .pop-up-leads form, .pop-up-apoint form, .pop-up-apoint-remove form, .pop-up-apoint_nextmonth form, .pop-up-apoint-remove_nextmonth form, .container-status{
        overflow: inherit
    }
    input.rela-inp {
        margin-left: 10px;
        margin-top: 0;
    }
    .agenda-comercial .add-button-plus-gradient{
        display: block;
    }
    .agenda-title a {
        top: 20px;
        bottom: inherit;
    }
    .agenda-title{
        height: auto;
    }
    .type-marcacao.active {
        width: 50%;
    }
    .content-form-agenda p{
        flex-direction: row;
    }
    .marcacao-row{
        flex-direction: row;
    }
    .marcacao-form{
        width: 48%;
    }
    .marcacao-form label{
        flex-direction: row;
    }
    .marcacao-form label input{
        margin-left: 10px;
    }
    .marcacao-form input[type="datetime-local"] {
        margin-left: 10px;
    }
    .marcacao-form .select{
        position: absolute;
        left: 80px;
        top: 0px;
    }
    .content-type-marcacao label {
        font-size: 22px;
    }
    .search-default .first-row{
        flex-direction: row;
    }
    .search-default label{
        width: 335px;
    }
    select {
        color: black;
        font-size: 16px;
    }
    .model-alerts {
        width: calc(100% - 100px);
        left: 100px;
    }
    !* #rota_pt_container .content-form-agenda{
         flex-direction: row;
     }*!
    .model-agenda-comercial-status-report .content-form-agenda button {
        margin-right: 20px;
        margin-bottom: 0px;

    }
    .stacked-row{
        flex-direction: row;
    }
    .row-item{
        width: calc(95%/4);
    }
    .model-agenda-comercial-status-report .content-form-agenda{
        flex-direction: row !important;
    }
}
#realizada-btn{
    background: linear-gradient(to bottom, #22b573 35%, #006837 180%) !important;
}
#nao-realizada-btn{
    background: linear-gradient(to right, red 0%,darkred 100%) !important;
}
#reagendade-btn{
    background: linear-gradient(to right, #6ab6ff 0%,#3f86ff 100%) !important;
}

.realizada-class{
    background: linear-gradient(to bottom, #22b573 35%, #006837 180%) !important;
}
.nao-ralizada-class {
    background: linear-gradient(to right, red 0%,darkred 100%) !important;
}
.reagendada-class{
    background: linear-gradient(to right, #6ab6ff 0%,#3f86ff 100%) !important;
}*/
