.new-agenda{
    max-width: none !important;
    overflow: hidden;
    overflow-y: scroll;
    align-items: flex-start !important;
}
.new-agenda form {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    display: block;
}
.new-agenda form label {
    justify-content: space-between;
    grid-template-columns: 55% 45% !important;
    align-items: center;
}
.new-agenda form  .divider {
    width: 100%;
    height: 2px;
    background: rgba(255,255,255,0.5);
    margin-top: 40px;
}
.item-agenda {
    cursor: pointer;
    padding: 8px 10px;
    box-sizing: border-box;
    transition: all 0.3s linear;
}
.item-agenda:hover {
    background: rgba(0,0,0,0.02);
}
.item-agenda .time{
    font-size: 14px;
    font-style: italic;
    margin-bottom: 5px;
    display: block;
    background: transparent;
}
.item-agenda .agenda-item span {
    width: 100%;
    text-align: center;
    padding: 6px 0;
    box-sizing: border-box;
    min-height: 36px;
}
.item-agenda.app .agenda-item{
    background: linear-gradient(to right,#7d00ff 0%,#c33fff 120%) !important;
}
.client-name {
    display: block;
}
.type-name {
    display: none;
}
.item-agenda:hover .client-name{
    display: none;
}
.item-agenda:hover .type-name {
    display: block;
}
#chamadas-projecao-container{
    overflow: hidden;
    min-height: 180px;
    margin-bottom: 0;
    margin-top: 40px;
}
#chamadas-projecao-container .agenda-item-container {
    display: flex;
    flex-direction: column;
}
#chamadas-projecao-container .agenda-column:first-child span {
    font-style: italic;
    font-weight: 700;
    min-height: 27px;
}
#chamadas-projecao-container .agenda-item-container span {
    margin-top: 10px;
    text-align: center;
}
#chamadas-projecao-container .agenda-item-container span input {
    width: 100%;
    max-width: 50px;
    border: 0;
    background: transparent;
    border-bottom: 1px solid black;
    text-align: center;
}

.telm {
    display: flex;
}

@media screen and (min-width: 1024px) {
    #chamadas-projecao-container .agenda-column {
        width: calc(95% / 9);
        min-width: 0px;
        margin-right: 20px;
    }
}