.table-format-one{
    border-spacing: 10px;

    width: 100%;
}
.table-format-one > thead:first-child > tr:first-child > th {
    font-size: 20px;
    font-weight: 600;
    padding: 8px 0;
    color: #cc66cc;
    font-style: italic;
    text-transform: uppercase;
}
.legend-btn {
    border: 0;
    background: -webkit-linear-gradient(top, #ffe66a -5%, #c652df 200%);
    border-radius: 20px;
    color: white;
    font-size: 18px;
    font-style: italic;
    padding: 4px 15px;
    cursor: pointer;
}
.table-format-one .table-format-one-first-row{
    background: #e6e6e6 !important;
    background: -moz-linear-gradient(top, #e6e6e6 0%, #b3b3b3 140%) !important;
    background: -webkit-linear-gradient(top, #e6e6e6 0%,#b3b3b3 140%) !important;
    background: linear-gradient(to bottom, #e6e6e6 0%,#b3b3b3 140%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6e6e6', endColorstr='#b3b3b3',GradientType=0 ) !important;
}
.table-format-one > tbody > tr > td {
    text-align: center;
    font-size: 16px;
    font-style: italic;
    cursor: pointer;
}
.table-format-one > tbody > tr:not(.table-format-one-first-row) > td {
    padding: 14px 12px;
}
.table-format-one > tbody > tr > td > input {
    border: 0;
    background: transparent;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    width: 100%;
    margin: 0 auto;
    display: block;
    padding: 12px 0px;
    color: white;
}
.table-format-one > tbody > tr > td > input:focus{
    outline: none;
}

.table-format-one > tbody > tr > td > input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
}
.table-format-one > tbody > tr > td > input::-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
}

.table-format-one > tbody > tr > td > input::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
}
.table-format-one > tbody > tr > td:first-child {
    border-radius: 20px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
   /* background: -webkit-linear-gradient(top, #6ab6ff 20%, #3f86ff 100%);
    background: linear-gradient(to bottom, #6ab6ff 20%, #3f86ff 100%) !important;*/
    color: white;
}
.table-format-one > tbody > tr > td:last-child {
    border-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: -webkit-linear-gradient(top, #c33fff 20%, #7d00ff 100%);
    background: linear-gradient(to bottom, #c33fff 20%, #7d00ff 100%) !important;
}
.insert_lead {
    color: white;
    background: transparent;
    border: 0;
    font-size: 22px;
    padding: 0 20px;
    font-weight: 700;
}
.red-call {
    background: #f62e0c;
    background: -moz-linear-gradient(top, #ef233c 0%, #d90429 100%);
    background: -webkit-linear-gradient(top, #ef233c 0%, #d90429 100%);
    background: linear-gradient(to bottom, #ef233c 0%, #d90429 100%);
    color: white;
}

.green-call {

    background: #23bf00;
    background: -moz-linear-gradient(top, #52b788 0%, #40916c 100%);
    background: -webkit-linear-gradient(top, #52b788 0%, #40916c 100%);
    background: linear-gradient(to bottom, #52b788 0%, #40916c 100%);
    color: white;
}

.yellow-call {
    background: -moz-linear-gradient(top, #fff05a 0%, #f1d302 100%);
    background: -webkit-linear-gradient(top, #fff05a 0%, #f1d302 100%);
    background: linear-gradient(to bottom, #fff05a 0%, #f1d302 100%);
    color: white;
}

.outros-call {
    background: -moz-linear-gradient(top, #c86aff 0%, #9a00e9 100%);
    background: -webkit-linear-gradient(top, #c86aff 0%, #9a00e9 100%);
    background: linear-gradient(to bottom, #c86aff 0%, #9a00e9 100%);
    color: white;
}
.to-call{
    background: -webkit-linear-gradient(top, #6ab6ff 20%, #3f86ff 100%);
    background: linear-gradient(to bottom, #6ab6ff 20%, #3f86ff 100%) !important;
    color: white;
}
.call-again{
    background: -moz-linear-gradient(top, #ffc42a 0%, #ffa415 100%);
    background: -webkit-linear-gradient(top, #ffc42a 0%, #ffa415 100%);
    background: linear-gradient(to bottom, #ffc42a 0%, #ffa415 100%);
    color: white;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
.table-inputs {
    color: white;
    background: linear-gradient(to bottom, #c33fff -35%, #7d00ff 120%);
    padding: 40px;
    box-sizing: border-box;
    margin-top: 20px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.table-inputs input {
    padding: 6px 10px;
    box-sizing: border-box;
    border-radius: 20px;
    border:0;
    width: 100%;
    max-width: 220px;
}
.table-inputs label, .table-inputs input, .table-inputs h2:not(:first-child){
    margin-top: 10px;
}
.table-inputs label input {
    margin-left: 20px;
}
.table-inputs-bottom {
    margin-bottom: 40px;
}
.table-inputs-bottom button,
.table-inputs button {
    background: #ffcc00;
    border: 0;
    padding: 8px 22px;
    color: white;
    font-style: italic;
    font-size: 20px;
    position: relative;
    cursor: pointer;
    border-radius: 20px;
    box-shadow: -2px 4px 6px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    width: 100%;
    max-width: 200px;
}
.table-inputs h2 {
    font-weight: 400;
    font-size: 20px;
}
input:focus {
    outline: none;
}
.table-input-info {
    font-style: italic;
    color: white;
    font-size: 12px;
    margin: 10px 0;
}
.table-grid-two-columns {
    display: grid;
    grid-template-columns: 100%;
    grid-column-gap: 10%;
}
.table-grid-five-columns {
    display: grid;
    grid-template-columns: 100%;
    grid-column-gap: 0%;
}

.table-grid-two-columns label {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.table-grid-five-columns label {
    grid-template-columns: 100%;
    grid-column-gap: 1%;
    display: grid;
    align-items: center;
    margin-top: 5px;
}
.table-grid-five-columns-table label {
    grid-template-columns: 30% 8% 8% 8% 8% 8% 8% 8% 8%;
    grid-column-gap: .5%;
    display: grid;
    align-items: center;
    margin-top: 5px;
}
.table-grid-five-columns-table label div {
    text-align: center;
}
.table-grid-five-columns-table label div:first-child  {
    display: flex;
    flex-direction: column;
}
.table-grid-five-columns-table label div span:last-child{
    margin-top: 20px;
}
.table-grid-five-columns-table label div input {
    margin-left: 0;
    text-align: center;
}
.table-grid-five-columns label:first-child div {
    text-align: center;
    font-weight: 800;
}
.table-grid-five-columns label input {
    margin-left: 0;
    text-align: center;
}
.table-grid-five-columns label input.red{
    color: red;
}
.table-grid-five-columns label input.green{
    color: green;
}
.table-grid-five-columns,
.table-grid-two-columns label div {
    display: flex;
    flex-direction: column;
}
.table-grid-five-columns,
.table-grid-two-columns label div span{
    font-size: 12px;
    font-style: italic;
}
.last-td-fixed > tbody > tr > td:last-child{
    width: 40px;
}
.last-td-fixed > tbody > tr > td:last-child button {
    background: transparent;
    border: 0;
    cursor: pointer;
}
.last-td-fixed > tbody > tr > td:last-child button svg {
    width: 16px;
    color: white;
}
.new-limpeza  {
    max-width: 500px !important;
    overflow: hidden;
    overflow-y: scroll;
    align-items: flex-start !important;
}

.new-limpeza form label{
    display: grid  !important;
    grid-template-columns: 100%  !important;
}

.new-limpeza form label span,  .new-aulas  form label span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.new-aulas {
    max-width: none !important;
    overflow: hidden;
    overflow-y: scroll;
    align-items: flex-start !important;
}
.new-aulas form {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
}
.top-form {
    display: grid;
    grid-template-columns: 45% 45%;
    grid-gap: 10%;
    row-gap: 0;
}
.new-aulas form label{
    display: grid  !important;
    grid-template-columns: 100% !important;
}
.new-aulas form .top-form label {
    grid-template-columns: 55% 45% !important;
}
.new-aulas form .area-column {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.new-personaltable form .area-column {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.new-aulas form h3, .new-personaltable form h3 {
    font-size: 24px;
    border-bottom: 3px solid white;
    width: auto;
    display: block;
    max-width: 300px;
    margin-top: 40px;
}

.new-personaltable form label{
    display: grid  !important;
    grid-template-columns:100%!important;
}
.new-personaltable {
    max-width: none !important;
    overflow: hidden;
    overflow-y: scroll;
    align-items: flex-start !important;
}
.new-personaltable form {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
}
.new-personaltable  form label span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.top-form {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 10%;
    row-gap: 0;
}

.new-personaltable form .top-form label {
    grid-template-columns: 100% !important;
}

.new-colaborador {
    max-width: none !important;
    overflow: hidden;
    overflow-y: scroll;
    align-items: flex-start !important;
}
.new-colaborador form {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 45% 45%;
    grid-gap: 10%;
    row-gap: 0;
}
.new-colaborador  form label span {
    display: flex;
    flex-direction: row;
    /*justify-content: center;*/
    align-items: center;
}

.new-colaborador  #delete {background:red;width:50%;}

.new-lead-modal  #delete {background:red; margin-left:20px;}

.new-colaborador  form label {
    display: grid  !important;
    grid-template-columns: 45% 45% !important;
    grid-gap: 10%;
    row-gap: 0;
    align-items: center;
}

.new-colaborador-reunioes form {
    grid-template-columns: 100%;
}
.new-ferias form label {
    display: grid  !important;
    grid-template-columns: 35% 60% ;
    grid-gap: 5%;
    row-gap: 0;
    align-items: center;
}
.label-full {
    grid-template-columns:100% !important;
}
.new-ferias form label select {
    margin-left: 0 !important;
}









table.calendar {
    border-collapse: collapse;
    width: 100%;
}

table.calendar thead {
    background: linear-gradient(to right,#7d00ff 50%,#c33fff 100%);
    color: white;
    margin-bottom: 3px;
    border-bottom: 2px solid white
}


table.calendar thead th {
    font-weight: normal;
    padding: 10px 0px;
}

table.calendar thead th.bolder {
    font-weight: bold;
}

table.calendar tbody {
    font-size: 0.8em;
}

table.calendar td {
    text-align: center;
    padding: 6px;
    font-size: 11px;
    cursor: pointer;
    border: 1px solid rgba(185, 185, 185, 0.13);
    background-color: white;
    min-width: 15px;
}

table.calendar tr:last-child td {
    border-bottom: none;
}

table.calendar td.month-name {
    font-weight: bold;
    text-align: left;
    cursor: default;
    border-left: none;
}

table.calendar td.prev-month,
table.calendar td.next-month {
    color: transparent;
    cursor: default;
    pointer-events: none;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAABZJREFUCB1jYEADmzdv/o8iRA0BoIEAKngPeSAlnXcAAAAASUVORK5CYII=');
}

table.calendar td.week-separator {
    pointer-events: none;
    padding: 0;
    width: 8px;
    min-width: 0;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAABZJREFUCB1jYEADmzdv/o8iRA0BoIEAKngPeSAlnXcAAAAASUVORK5CYII=');
}

table.calendar td.bolder {
    font-weight: bold;
}

/* Single selected day */
table.calendar td.selected {
    background-color: orangered;
    color: white;
    font-weight: bold;
}

/* Selected range */
table.calendar td.range {
    background-color: rgba(255,69,0, 0.7);
    font-weight: bold;
    color: white;
}

table.calendar td.range-left {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    overflow: hidden;
    background: orangered;
}

table.calendar td.range-right {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    overflow: hidden;
    background: orangered;
}

div.calendar-controls {
    margin: 5px auto;
    display: table;
    font-size: 25px;
    line-height: 35px;
}

div.calendar-controls div {
    display: inline;
}

div.calendar-controls .current-year {
    margin: 0 30px;
}

div.calendar-controls .control {
    font-weight: bolder;
    color: #323232;
    font-size: 0.8em;
    cursor: pointer;
}

div.calendar-controls .today {
    position: absolute;
    right: 15px;
    line-height: 35px;
    font-size: 0.6em;
    text-transform: uppercase;
}

/* Demo */

div#calendar {
    position: relative;
    border-radius: 5px;
    border: 1px solid #5A5A5A;
    background-color: white;
    overflow: hidden;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


div.options {
    border: 1px solid #B9B9B9;
    border-radius: 5px;
    padding: 10px 15px;
    margin-top: 30px;
}

div.options select {
    margin-left: 10px;
}

.calendar-mapa {
    margin-top: 40px;
    margin-bottom: 40px;
}
table.calendar td.holidays{
    background: #fcee21 !important;
    color: black;
}

table.calendar td.feriados{
    background: red !important;
    color: white !important;
}
table.calendar td.spring,
table.calendar td.summer {
    background: grey;
    color: white;
}
table.calendar td.selected{
    background: #7d00ff;
    color: white;
}
.table-inputs-filtros {
    color: white;
    background: linear-gradient(to bottom, #c33fff -35%, #7d00ff 120%);
    padding: 20px 40px;
    box-sizing: border-box;
    margin-top: 20px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.table-inputs-filtros select{

}
.table-inputs-filtros  select{
    background: linear-gradient(to bottom, #fcee21 -46%, #f89d1e 217%);
    color: white;
    border: 0;
    font-size: 16px;
    padding: 4px 10px;
    box-sizing: border-box;
    border-radius: 20px;
    margin-left: 10px;
}
.table-inputs-filtros label h2 {
    font-size: 22px;
    margin-bottom: 10px;
    border-bottom: 2px solid;
    display: block;
    max-width: 200px;
}
.loading-spinner{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: linear-gradient(to right,#7d00ff 50%,#c33fff 100%);
    opacity: 0.4;
    z-index: 99999999;
}
.hide-desktop {
    display: block;
    margin-top: 5px;
    font-size: 10px;
}
.table-format-one tr td:last-child svg{
    width: 15px;
    display: block;
    margin: 0 auto;

}
.row-delete{
    font-weight: 900;
    font-style: normal;
    width: 60px;
}
.socios-fixed-table td {
    text-transform: uppercase;
}

.label-total-receitas {
    border-bottom: 1px solid white;
    font-style: italic;
    margin-top: 20px;
}
.label-total-receitas span {
    font-style: normal;
    font-weight: 700;
}
.table-grid-two-columns label div span {
    display: flex;
    align-items: center;
}
.table-grid-two-columns label div span input {
    background: transparent;
    color: white;
    border-bottom: 1px solid white;
    border-radius: 0;
}
.dd_ana td {
    border: 1px solid rgb(85, 44, 198);
    text-align: center;
    padding: 6px 0;
    border-bottom: 1px solid #552cc6;
}
.dd_ana td table td {
    border: 0
}
.dd_ana td table td input {
    text-align: center;
    border: 0
}
.dd_ana td table {
    width: 100%;
    min-width: 90px;
}
@media screen and (min-width: 1024px){
    .new-aulas form label{
        grid-template-columns: 50% 15% 15% 20% !important;
    }
    .new-limpeza form label{
        grid-template-columns: 60% 20% 20%  !important;
    }
    .new-limpeza label span:first-child{
        margin-left: 40px;
    }
    .top-form {
        grid-template-columns: 45% 45%;
    }
    .table-grid-five-columns label {
        grid-template-columns: 19% 19% 19% 19% 19%;
    }
    .hide-desktop {
        display: none;
    }
    .new-personaltable form .top-form label {
        grid-template-columns: 55% 45% !important;
    }
    .new-personaltable form label{
        display: grid  !important;
        grid-template-columns: 35% 15% 15% 20% 15%!important;
    }
    .table-grid-two-columns {
        grid-template-columns: 45% 45%;
    }
}
@media screen and (min-width: 1300px){
    table.calendar td {
        padding: 8px;
        font-size: 14px;
    }
    table.calendar thead th {
        padding: 10px 3px;
    }
}