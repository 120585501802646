body,html {
    width: 100%;
    height: 100%;
}
.login-img {
    /* width: 100%;
     max-width: 220px;
     height:75px;*/
    margin-bottom: 20px;
}

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffe66a+0,c652df+100 */
    background: #ffe66a; /* Old browsers */
    background: -moz-linear-gradient(top, #ffe66a 0%, #c652df 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #ffe66a 0%, #c652df 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #ffe66a 0%, #c652df 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe66a', endColorstr='#c652df', GradientType=0); /* IE6-9 */

}

.login-container h1 {
    color: #f6f9fa;
    font-size: 3rem;
    font-weight: lighter;
    margin: 5px 0px 30px 0;
    text-transform: uppercase;
}

.login-container form {
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    max-width: 320px;
    flex-direction: column;
}

.form-group {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.form-group i.img {
    width: 16px;
    position: absolute;
    left: 20px;
    top: 12px;
}

i.user {
    width: 16px;
    height: 16px;
    background: url("./img/user-light.svg") no-repeat;

}

i.lock {
    width: 16px;
    height: 16px;
    background: url("./img//unlock-light.svg") no-repeat;

}

.login-container  form input {
    padding: 10px 20px;
    background: transparent;
    border: 2px solid white;
    border-radius: 20px;
    color: #ffcc00;
    font-size: 11pt;
    font-style: italic;
    font-weight: 300;
    font-family: Roboto;
    padding-left: 46px;
}

.login-container form input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
}

.login-container form input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
}

.login-container form input::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
}

.login-container form button {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffdb4c+0,ffcc00+100 */
    background: #ffdb4c; /* Old browsers */
    background: -moz-linear-gradient(45deg, #ffdb4c 0%, #ffcc00 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #ffdb4c 0%, #ffcc00 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #ffdb4c 0%, #ffcc00 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdb4c', endColorstr='#ffcc00', GradientType=1); /* IE6-9 fallback on horizontal gradient */
    border: 0;
    padding: 8px 16px;
    color: white;
    border-radius: 40px;
    cursor: pointer;
    font-size: 18pt;
    font-style: italic;
    font-weight: 300;
    font-family: Roboto;
}

.login-container form button:hover {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffcc00+0,ffdb4c+100 */
    background: #ffcc00; /* Old browsers */
    background: -moz-linear-gradient(45deg, #ffcc00 0%, #ffdb4c 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #ffcc00 0%, #ffdb4c 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #ffcc00 0%, #ffdb4c 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffcc00', endColorstr='#ffdb4c', GradientType=1); /* IE6-9 fallback on horizontal gradient */

    transition: all 0.3s linear;
}

.alert-danger {
    background: red;
    width: 100%;

    color: white;
    padding: 10px 12px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 40px;
    opacity: 0;
    margin-top: 20px;
    line-height: 20px;
}
.alert-danger.active {
    opacity: 1;
}

/* Change the white to any color ;) */
.login-container input:-webkit-autofill,
.login-container input:-webkit-autofill:hover,
.login-container input:-webkit-autofill:focus,
.login-container input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px transparent inset !important;
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

@-webkit-keyframes autofill {
    to {
        color: #ffcc00;
        background: transparent;
    }
}


