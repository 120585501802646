#main-container{
    padding-top: 80px;
}
.container {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    display: block;
}
.current-mes-week {
    margin-top: 20px;
    font-weight: 700;
    color: #7d00ff;
    font-style: italic;
}
.graph-container{
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
}
.graph-container-2-columns, .graph-container-3-columns , .graph-container-4-columns{
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
}
.graph-item{
    width: 50%;
}
.apexcharts-toolbar{
    display: none !important;
}

.content-container {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}
.sms-total-value {
    text-align: center;
    width: 100%;
    display: block;
    background: -webkit-linear-gradient(top, #ffe66a -5%, #c652df 200%);
    margin: 20px auto;
    color: white;
    max-width: 400px;
    font-style: italic;
    padding: 10px 18px;
    box-sizing: border-box;
    font-size: 28px;
    border-radius: 40px;
    text-transform: uppercase;
    font-weight: 300;
    box-shadow: -2px 3px 5px rgba(0,0,0,0.2)
}
.stacked-row {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.row-item{
    width: 100%;

}
.row-item div{
    background: linear-gradient(to bottom, #22b573 -35%, #006837 120%);
    padding: 20px;
    box-sizing: border-box;
    border-radius: 40px;
    color: white;
    text-transform: uppercase;
    text-align: center;
    margin-top: 10px;
    font-style: italic;
    box-shadow: -2px 3px 5px rgba(0,0,0,0.2)
}
.row-item div:first-child{
    background: linear-gradient(to bottom, #c33fff -35%, #7d00ff 120%);
    margin-top: 0px;
    font-weight: 600;
    font-size: 22px;
}
.new-btn {
    background: #ffcc00;
    border: 0;
    padding: 8px 41px;
    color: white;
    font-style: italic;
    font-size: 20px;
    position: relative;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 20px;
    box-shadow: -2px 4px 6px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    margin-left: 20px;
    max-width: calc(100% - 40px);
}
.graph-container .apexcharts-legend-series .apexcharts-legend-marker{
    background: #7d00ff !important;
}
.graph-container .apexcharts-legend-series:last-child .apexcharts-legend-marker{
    background: #c33fff !important;
}
.graph-container .apexcharts-tooltip-series-group .apexcharts-tooltip-marker.graph-item{
    background: #7d00ff !important;
}
.graph-container .apexcharts-tooltip-series-group:last-child .apexcharts-tooltip-marker{
    background: #c33fff !important;
}
.apexcharts-canvas {
    box-shadow: 0px 1px 10px -12px #607D8B;
    background-color: #fff;
}
.graph-container-1-columns {
    width: 100%;
    margin-top: 40px;
}
.alerts-container {
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: space-around;
}
.alerts-container .sms-total-value {
    margin: 0;
    font-size: 20px;
    max-width: 260px;
}
.alerts-container .sms-total-value strong {
    font-weight: 800;
}
.apexcharts-xaxis-texts-g text {
    text-transform: capitalize !important;
}
.table-inputs-filtros-contactos{
    width: 100%;
    max-width: 1280px;
    margin: 10px auto;
}
@media screen and (min-width: 1024px){
    .row-item{
        width: calc(95%/4);
    }
    .stacked-row{
        flex-direction: row;
    }
    .graph-item{
        width: calc(95% / 5);
        margin-right: 1%;
    }
    .graph-container-2-columns .graph-item{
        width: calc(95% / 2);
        margin-right: 2.5%;
    }
    .graph-container-3-columns .graph-item{
        width: calc(95% / 3);
        margin-right: 1.5%;
    }
    .graph-container-4-columns .graph-item{
        width: calc(95% / 4);
        margin-right: 1%;
    }
    .graph-container-1-columns .graph-item{
        width: calc(100%);

    }
}
@media screen and (min-width: 1290px){

    .container {
        max-width: 1280px;
    }
}