
.new-lead-modal{
    position: fixed;
    right: -100%;
    bottom: 0;
    width: 100%;
    max-width: 400px;
    padding: 20px;
    box-sizing: border-box;
    height: calc(100% - 80px);
    display: flex;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    z-index: 99999;
    background: linear-gradient(to bottom, #6ab6ff 20%, #3f86ff 100%) !important;
    transition: all 0.3s linear;
    box-shadow: -7px 0px 7px -5px rgba(0,0,0,0.2);

}
.new-lead-modal.active {
    opacity: 1;
    visibility: visible;
    right: 0;
}
#close-new-lead-modal svg {
    width: 30px;
    color: white;
    transform: rotate(45deg);
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}
.new-lead-modal form h2{
    color: white;
    font-size: 26px;
    display: flex;
    margin-top: 20px;
}
.new-lead-modal form h2 input {
    padding: 10px 20px;
    background: transparent;
    border: 2px solid white;
    border-radius: 20px;
    color: white;
    font-size: 12pt;
    font-style: italic;
    font-weight: 300;
    width: 100%;
}
.new-lead-modal form label{
    color: white;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
    font-style: italic;
    text-transform: uppercase;
    align-items: center;
    text-align: center;
}

.new-lead-modal form label select{
    background: linear-gradient(to bottom, #fcee21 -46%, #f89d1e 217%);
    color: white;
    border: 0;
    font-size: 16px;
    padding: 4px 10px;
    box-sizing: border-box;
    border-radius: 20px;
    margin-left: 10px;
    width: 100%;
    text-align: center;
}
select option {
    color: black;
}
.form-column{
    flex-direction: column;
}
.form-column input {
    margin-top: 10px;
}
.new-lead-modal form label input {
    padding: 10px 20px;
    background: transparent;
    border: 2px solid white;
    border-radius: 20px;
    color: white;
    font-size: 11pt;
    font-style: italic;
    font-weight: 300;
    width: 90%;
    margin-left: 10px;
}
.new-lead-modal form label textarea {
    width: 100%;
    padding: 20px 30px;
    box-sizing: border-box;
    border: 0;
    background: rgba(255,255,255,0.6);
    font-style: italic;
    border-radius: 40px;
}
.new-lead-modal form  button{
    background: #ffcc00;
    border: 0;
    padding: 8px 22px;
    color: white;
    font-style: italic;
    font-size: 20px;
    position: relative;
    cursor: pointer;
    border-radius: 20px;
    box-shadow: -2px 4px 6px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
}
.new-manutencao form{
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 0%;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
}
.new-manutencao form label {
    align-items: center;
    justify-content: space-between;
    display: grid;
    grid-template-columns: 100%;
}
.new-socio, .new-manutencao  {
    max-width: none !important;
    overflow: hidden;
    overflow-y: scroll;
    align-items: flex-start !important;
}
.new-socio form{
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 0%;
    margin: 0 auto;
    width: 100%;
    max-width: 1280px;
}
.new-socio.new-personaltable form .socio-list-viagem label {
    display: grid !important;
    grid-template-columns: 100% !important;
}
.new-personaltable.new-socio form .socio-list-viagem label span {
    flex-direction: row;
}
.new-personaltable form span input[type="checkbox"], 
.new-colaborador form span input[type="checkbox"]  {
    width: 50px;
    height: 25px;
    border: 0;
    cursor: pointer;
    background: transparent;
}
.table-inputs-filtros-contactos {
    display: grid;
    grid-template-columns: 50% 50%;
    row-gap: 20px;
}
.table-inputs-filtros-contactos label {
    display: flex;
    align-items: center;
}
.table-inputs-filtros input {
    border: 0;
    padding: 4px 20px;
    box-sizing: border-box;
    border-radius: 20px;
    margin-left: 10px;
}
.pagination {
    display: flex;
    justify-content: center;
    margin: 20px 0 !important;
    flex-wrap: wrap;
}
.pagination li {
    margin-bottom: 20px;
}
.pagination a {
    color: #7d00ff;
    cursor: pointer;
    padding: 5px 15px;
    box-sizing: border-box;
    border: 1px solid #7d00ff;
    margin-right: 5px;
}
.pagination li.active a{
    background: linear-gradient(to right,#7d00ff 50%,#c33fff 150%);
    color: white;
}
.new-personaltable form label.fix-label-column {
    grid-template-columns: 100% !important;
}
.new-personaltable form label.fix-label-column textarea{
    margin-top: 10px;
}
.new-personaltable form  .label-oferta h3 {
    margin: 0;
    max-width: none;
}
.new-lead-modal form label input[type="radio"]{
    width: 30px;
    height: 20px;
    margin: 0;
    margin-top: 5px;
    cursor: pointer;
}
@media screen and (max-height: 760px){
    .new-lead-modal{
        overflow: hidden;
        overflow-y: scroll;
        align-items: flex-start;
    }
}
@media screen and (min-width: 1024px){
    .new-lead-modal {
        padding: 40px;
        box-sizing: border-box;
    }
    .new-lead-modal form label {
        flex-direction: row;
        text-align: left;
    }
    .new-lead-modal form label input {
        max-width: 220px;
    }
    .new-manutencao form  label input {
        max-width: 100% !important;
        width: 100%;
    }
    .new-lead-modal form label select {
        max-width: 220px;
    }
    .new-manutencao form label {
        grid-template-columns: 40% 60%;
    }
    .new-socio.new-personaltable form .socio-list-viagem label {
        grid-template-columns: 25% 25% 25% 25% !important;
    }
    .new-socio.new-personaltable form .socio-list-viagem label.label-oferta {
        grid-template-columns: 50% 15% !important;
    }
}
@media screen and (min-width: 1324px){
    .table-inputs-filtros-contactos  {
        grid-template-columns: 25% 25% 25% 25%;
    }
}
