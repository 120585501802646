
.page-header{
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    height: 80px;
    width: 100%;
    z-index: 99999;
    padding: 0 20px;
    box-sizing: border-box;
    background: #c33fff; /* Old browsers */
    background: -moz-linear-gradient(right,#7d00ff 50%,#c33fff 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(right,#7d00ff 50%,#c33fff 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,#7d00ff 50%,#c33fff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c33fff', endColorstr='#7d00ff', GradientType=0); /* IE6-9 */
}
.logo-bg{
    width: 160px;
}
.menu-container {
    display: flex;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.menu-container-items {
    display: none;
    align-items: center;
    height: 100%;
}
.mobile-menu-container-items {
    display: none;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 999999;
    padding: 0 20px;
    position: fixed;
    box-sizing: border-box;
    background: #c33fff; /* Old browsers */
    background: -moz-linear-gradient(right,#7d00ff 50%,#c33fff 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(right,#7d00ff 50%,#c33fff 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,#7d00ff 50%,#c33fff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c33fff', endColorstr='#7d00ff', GradientType=0); /* IE6-9 */
}
.mobile-menu-container-items.active {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.mobile-menu-container-items a {
    color: white;
    text-decoration: none;
    margin-bottom: 10px;
    font-size: 22px;
}
.menu-container a {
    text-decoration: none;
    color: white;
    line-height: 74px;
}
.menu-container li a {
    line-height: 19px;
}
.menu-container li {
    list-style: none;
}
.in-menu {
    color: white;
    margin: 0 0px;
    cursor: pointer;
    height: 100%;
    align-items: center;
    display: flex;
    padding: 0 14px;
    box-sizing: border-box;
    transition: all 0.3s linear;
    font-weight: 400;
    font-size: 12px;
}
.in-menu svg {
    width: 25px;
    margin-right: 10px;
}
.serviceclient-icon svg {
    width: 22px;
}
.in-menu:hover {
    background: rgba(255,255,255,0.1);
}
.in-menu ul{
    position: absolute;
    top: 64px;
    left: 0;
    background: #c33fff; /* Old browsers */
    background: -moz-linear-gradient(right,#7d00ff 50%,#c33fff 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(right,#7d00ff 50%,#c33fff 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,#7d00ff 50%,#c33fff 100%);
    padding: 20px;
    box-sizing: border-box;
    display: none;
    line-height: 20px;
    flex-direction: row;
    width: 100%;
    border-top: 2px solid white;
}
.in-menu ul div {
    width: 100%;
    max-width: 1280px;
    flex-direction: column;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 30% 40%;
    justify-content: flex-start;
    padding: 20px 0;
    box-sizing: border-box;
}
.in-menu:hover > ul {
    display: flex;
}
.in-menu ul li {
    margin: 0 10px;
    border-bottom: 2px solid transparent;
    padding-bottom: 2px;
    margin-bottom: 5px;
}
.in-menu ul li:hover a {
    border-bottom: 2px solid white;
}



.icon-menu-mobile {
    isplay: block;
    position: fixed;
    bottom: 26px;
    right: 20px;
    background: white;
    width: 50px;
    height: 44px;
    cursor: pointer;
    z-index: 9999;
    padding: 16px 13px;
    border-radius: 100%;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
    transition: all 0.3s linear;
}

.bar {
  width: 24px;
  position: absolute;
  height: 3px;
  border-radius: 20%;
  background: linear-gradient(135deg, rgba(92, 101, 168, 1) -15%, rgba(177, 118, 172, 1) 72%, rgba(92, 101, 168, 1) 100%);
  transition: all 0.3s linear;
}

.top-bar {
    top: 16px;
}

.middle-bar {
    top: 22px;
    opacity: 1;
}

.bottom-bar {
    top: 28px;
}
.icon-menu-mobile.active .top-bar {
    transform: rotate(45deg);
    top: 22px;
}

.icon-menu-mobile.active .middle-bar {
    opacity: 0;
}

.icon-menu-mobile.active .bottom-bar {
    transform: rotate(-45deg);
    top: 22px;
}
@media screen and (min-width: 1024px){
    .icon-menu-mobile {
        display: none;
    }
    .menu-container-items {
        display: flex;
    }
    .menu-container{
        justify-content: space-between;
    }
    .in-menu {
        font-size: 16px;
    }
}